import React, { useState } from "react";
import toast from "react-hot-toast";
import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";
import { updateLeave } from "../../api/services/hroneApi";
import {
  StyledInputGroup,
  StyledInputContainer,
  StyledTextarea,
  StyledLabel,
} from "../../StyledCss/styledInputs";
const LeaveRejectForm = ({
  rowData,
  getLeavesReqData,
  setReject,
  setRowData,
  closeBtn,
}) => {
  // const [patterError, setInputPatternError] = useState(false);
  const [rejectedReason, setRejectReason] = useState("");
  const notifyRejected = (type) => {
    toast.success(`${type} Rejected`);
  };

  const sendRejectStatus = async () => {
    const empLeaveId = rowData.leaveId;
    const leaveStatus = rowData.status;
    const response = await updateLeave(leaveStatus, empLeaveId, rejectedReason);

    if (response.ok) {
      notifyRejected(rowData.requestType);
      getLeavesReqData();
    } else {
      getLeavesReqData();
    }
  };

  const onChangeReason = (e) => {
    setRejectReason(e.target.value);
    if (e.target.value !== "") {
      // setInputPatternError(false);
    }
    setRowData({ ...rowData, rejectedReason: e.target.value });
  };

  const onClickSubmitReason = (event) => {
    event.preventDefault();
    const pattern = /^(?!\s)[a-zA-Z0-9\s.,'()" -]{3,500}$/;
    if (pattern.test(rejectedReason)) {
      setReject(false);
      sendRejectStatus();
    } else {
      // setInputPatternError(true);
    }
  };

  return (
    <form onSubmit={onClickSubmitReason}>
      {/* <div className="d-flex justify-content-between align-items-center">
        <label className="reason-label" htmlFor="reasonInput">
          Reason*
        </label>
      </div>

      <textarea
        className={patterError ? "reason-input-error" : "reason-input"}
        onChange={onChangeReason}
        rows="5"
        cols="50"
      >
        {}
      </textarea> */}

      <StyledInputGroup>
        <StyledInputContainer>
          <StyledTextarea onChange={onChangeReason} value={rejectedReason} />
          <StyledLabel
            hasValue={rejectedReason.trim() !== ""}
            className="user-label"
          >
            Reason*
          </StyledLabel>
        </StyledInputContainer>
      </StyledInputGroup>

      <div className="d-flex justify-content-end gap-3 mt-3">
        <SecondaryButton onClick={() => closeBtn()} type="button">
          Cancel
        </SecondaryButton>
        <PrimaryButton type="submit">Submit</PrimaryButton>
      </div>
    </form>
  );
};

export default LeaveRejectForm;

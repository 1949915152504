/* eslint-disable react-hooks/exhaustive-deps */
import { getToken, getMessaging } from "firebase/messaging";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import {
  authentication,
  tenantDataBeforeLogin,
} from "../../api/services/hroneApi";
import { setDeviceToken } from "../../../src/redux/slices/notificationSlice";
import jwt_decode from "jwt-decode";
import { setUserLoggedIn } from "../../redux/slices/notificationSlice";
import "./index.css";

// import { Input } from "../../StyledCss/styledComponents";
import { LoginPageButton } from "../../StyledCss/styledbtn";
import {
  StyledInput,
  StyledInputContainer,
  StyledInputGroup,
  StyledLabel,
} from "../../StyledCss/styledInputs";
const apiStatusConstants = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};
const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [errMsg, setErrMsg] = useState(false);
  const [errMsgDisplay, setErrMsgDisplay] = useState("Something Went Wrong");
  const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);
  const [showPswd, setShowPswd] = useState(false);
  const image = process.env.PUBLIC_URL + "/urbuddilogo.png";

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginDetails = JSON.parse(localStorage.getItem("loginDetails"));
  const deviceToken = useSelector((state) => state.notifications.deviceToken);

  const handleForgotPassword = () => {
    navigate("/forgot-passwd");
  };

  useEffect(() => {
    async function requestPermissionAndToken() {
      try {
        const messaging = getMessaging();
        const permission = await Notification.requestPermission();

        if (permission === "granted") {
          try {
            const currentToken = await getToken(messaging, {
              vapidKey: process.env.REACT_APP_VAPID_KEY,
            });
            if (currentToken) {
              localStorage.setItem("devToken", JSON.stringify(currentToken));
              dispatch(setDeviceToken(currentToken));
            }
          } catch (error) {}
        }
      } catch (error) {}
    }

    requestPermissionAndToken();
    return () => {}; // eslint-disable-next-line
  }, []);

  useEffect(() => {
    sessionStorage.removeItem("userResetToken");
    getTenantDetails();
    getDomainUrl();
  }, []);

  // Get the token for this device

  // const handleEmailBlur = () => {
  //   if (email.trim() === "") {
  //     setEmailError("*required");
  //   } else {
  //     setEmailError("");
  //   }
  // };

  // const handlePasswordBlur = () => {
  //   if (password.trim() === "") {
  //     setPasswordError("*required");
  //   } else {
  //     setPasswordError("");
  //   }
  // };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setErrMsg(false);

    if (password.trim() !== "") {
      setPassword(password);
      setPasswordError("");
    } else {
      setPassword(password);
      setPasswordError("*required");
    }
  };

  const [invalidEmail, setInvalidEmail] = useState("");

  const handleEmailChange = (e) => {
    const email = e.target.value;
    const pattern = /^(?!\s)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setEmailError("");
    setErrMsg(false);

    if (pattern.test(email)) {
      setEmail(email);
      setInvalidEmail(false);
    } else if (email.trim() === "") {
      setEmail(email);
      setInvalidEmail(true);
      setEmailError("*required");
    } else {
      setEmail(email);
      setInvalidEmail(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email.trim() === "") {
      setEmailError("*required");
      console.log("error email");
    }

    if (password.trim() === "") {
      setPasswordError("*required");
    }

    if (email.trim() !== "" && password.trim() !== "") {
      const currentPageUrl = window.location.href;
      // const currentPageUrl = "openskale.urbuddi.com";
      const subdomain = new URL(currentPageUrl).hostname.split(".")[0];

      let tenantDomainName = "";
      if (subdomain === "dev" || subdomain === "localhost") {
        tenantDomainName = "optimworks";
      } else {
        tenantDomainName = subdomain;
      }

      try {
        setApiStatus(apiStatusConstants.inProgress);
        const loginObject = {
          email,
          password,
          deviceToken,
          domainName: tenantDomainName,
        };

        const response = await authentication(loginObject);

        if (response.status === 200) {
          const jwtToken = response.data.token;
          dispatch(setUserLoggedIn());
          const userObject = jwt_decode(jwtToken);

          const orgDetails = {
            primaryColor: userObject.primary_color,
            secondaryColor: userObject.secondary_color,
            logoUrl: userObject.logo_url,
            profilePic: userObject.profile_picture_url,
          };

          localStorage.setItem("loginDetails", JSON.stringify(jwtToken));
          setApiStatus(apiStatusConstants.success);
          localStorage.setItem("org", JSON.stringify(orgDetails));
          // dispatch(setUserLoggedIn());
          navigate("/");
        }
      } catch (error) {
        setErrMsg(true);
        setApiStatus(apiStatusConstants.failure);
        if (
          error.response &&
          error.response.data &&
          error.response.data.detail
        ) {
          setErrMsgDisplay(error.response.data.detail);
        }
      }
    }
  };

  const getDomainUrl = () => {
    const fullUrl = window.location.href;

    const subdomainMatch = fullUrl.match(
      /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n]+)/im
    );

    // Extract the subdomain from the match
    const subdomain = subdomainMatch ? subdomainMatch[1] : null;

    if (subdomain === "localhost") {
      return "optimworks.urbuddi.com";
    } else {
      return subdomain;
    }
  };

  const getTenantDetails = async () => {
    try {
      const domainUrl = getDomainUrl();
      const response = await tenantDataBeforeLogin(domainUrl);

      if (response.status === 200) {
      }
    } catch (error) {}
  };

  // const displayLoader = () => (
  //   <div className="loader">
  //     <div className="inner_loader"></div>
  //   </div>
  // );

  const loginVectorImage = process.env.PUBLIC_URL + "/loginImage.png";

  const renderLoginpage = () => (
    <div className="login-page-container">
      <img style={{ height: "90%" }} src={loginVectorImage} alt="loginVector" />
      <div style={{ marginLeft: "5%" }}>
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          className="login-card  d-flex flex-column"
        >
          {/* {apiStatus === "IN_PROGRESS" && displayLoader()} */}

          <img className="company-logo" src={image} alt="companylogo" />

          <p className="welcomeMessage">
            Welcome to <span style={{ color: "#E86020" }}>urBuddi</span>
          </p>
          <p className="subtext">Enter your login credentials here</p>

          <StyledInputGroup style={{ marginTop: "30px" }}>
            <StyledInputContainer>
              <StyledInput
                type="email"
                style={emailError !== "" ? { borderColor: "red" } : {}}
                // className={`${emailError === "" && "is-invalid"} input-element`}
                // placeholder="ENTER YOUR EMAIL"
                id="userEmail"
                value={email}
                // onChange={(e) => {
                //   setEmail(e.target.value);
                //   setEmailError("");
                //   setErrMsg(false);
                // }}
                onChange={handleEmailChange}
                // onBlur={handleEmailBlur}
              />
              <StyledLabel hasValue={true} className="user-label">
                Email*
              </StyledLabel>
            </StyledInputContainer>
          </StyledInputGroup>

          {invalidEmail && (
            <p style={{ color: "red", marginBottom: "0px" }}>{invalidEmail}</p>
          )}
          {emailError && (
            <p style={{ color: "red", marginBottom: "0px" }}>{emailError}</p>
          )}

          <div className="password-container">
            <StyledInputGroup>
              <StyledInputContainer>
                <StyledInput
                  style={passwordError !== "" ? { borderColor: "red" } : {}}
                  type={showPswd ? "text" : "password"}
                  // className={`${passwordError && "is-invalid"} input-element`}
                  id="userPassword"
                  // placeholder="ENTER YOUR PASSWORD"
                  value={password}
                  onChange={handlePasswordChange}
                  // onBlur={handlePasswordBlur}
                  // style={errors.firstName && { borderLeft: "10px solid red" }}
                />
                <StyledLabel className="user-label">Password*</StyledLabel>
              </StyledInputContainer>
            </StyledInputGroup>

            {!showPswd ? (
              <i
                onClick={() => setShowPswd(!showPswd)}
                className="fa-solid fa-eye-slash  eyeicon"
              ></i>
            ) : (
              <i
                onClick={() => setShowPswd(!showPswd)}
                className="fa-solid fa-eye eyeicon"
              ></i>
            )}
          </div>

          {/* <p>{passwordError}</p> */}

          {passwordError && (
            <p style={{ color: "red", marginBottom: "0px" }}>{passwordError}</p>
          )}

          {errMsg && <p className="err-msg-display mt-3">*{errMsgDisplay}</p>}

          <LoginPageButton type="submit">
            {apiStatus === apiStatusConstants.inProgress
              ? "Logging In"
              : "Login"}
          </LoginPageButton>
          <div className="d-flex justify-content-end forgot-btn-div">
            <button
              onClick={handleForgotPassword}
              type="button"
              className="forgot-pswd"
            >
              FORGOT PASSWORD?
            </button>
          </div>
        </form>
      </div>
    </div>
  );

  return <>{loginDetails !== null ? <Navigate to="/" /> : renderLoginpage()}</>;
};

export default LoginForm;

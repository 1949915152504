/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
// import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";
import { getUserData } from "../../api/services/hroneApi";
import Header from "../Header";
import LeavesSummary from "../LeavesSummary";
import LeftNavBar from "../LeftNavBar";
import PageHeader from "../PageHeader";
import TotalLeavesHistory from "../TotalLeavesHistory";
import "./index.css";
// import PopupModal from "../PopupModal";
import { PageHeaderName } from "../../StyledCss/styledComponents";

const LeavesOverviewManagement = () => {
  const [activeTab, setActiveTab] = useState("All History");
  // const [isWarningModal, setIsWarningModal] = useState(false);
  // const [refetchData, setRefetchData] = useState(false);
 

  const pageTabsList = [
    {
      id: 1,
      name: "All History",
      roles: ["Super Admin", "Admin", "Lead", "HR"],
    },
    {
      id: 2,
      name: "Summary",
      roles: ["Super Admin", "Admin", "HR"],
    },
  ];
  const { role } = getUserData();
  const orgDetails = JSON.parse(localStorage.getItem("org"));
  const { primaryColor } = orgDetails;

  const tabs = () => (
    <div className="d-flex align-items-center leaves-tab-container">
      {pageTabsList.map(
        (eachTab) =>
          eachTab.roles.includes(role) && (
            <button
              style={
                activeTab === eachTab.name
                  ? {
                    color: primaryColor,
                    borderBottom: `3px solid ${primaryColor}`,
                  }
                  : {}
              }
              className={
                activeTab === eachTab.name ? "leave-active-tab" : "leave-tab"
              }
              onClick={() => setActiveTab(eachTab.name)}
              key={eachTab.id}
            >
              {eachTab.name}
            </button>
          )
      )}
    </div>
  );

  const renderActiveTabPage = () => {
    switch (activeTab) {
      case "All History":
        return <TotalLeavesHistory  />;
      case "Summary":
        return <LeavesSummary />;
      default:
        return null;
    }
  };





  return (
    <>
      <Header />
      <div className="d-flex">
        <LeftNavBar />
        <div className="leaves-req-hist-container">
          <PageHeader>
            <PageHeaderName>Leave Overview Management</PageHeaderName>
          </PageHeader>
          <div className="page-content-container">
            {tabs()}
            {renderActiveTabPage()}


          </div>
        </div>
      </div>
    </>
  );
};

export default LeavesOverviewManagement;

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useState } from "react";
import "./index.css";

import toast, { Toaster } from "react-hot-toast";
import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";
import FailureView from "../FailureView";
import LoadingView from "../LoadingView";
import { getPendingLeaves, updateLeave } from "../../api/services/hroneApi";
import formatDate from "../../util/formatdate";
import LeaveRejectForm from "../LeaveRejectForm/leaveRejectForm";
import PopupModal from "../PopupModal";
const apiStatusConstants = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const LeaveRequestsRoute = () => {
  const [leavesReqData, setLeavesReqData] = useState([]);
  const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);
  const [rejectClicked, setReject] = useState(false);
  const [approvalApiStatus, setApprovalApiStatus] = useState(
    apiStatusConstants.initial
  );
  const [rowData, setRowData] = useState({});
  const notifyApproved = (type) => {
    toast.success(`${type} Approved`);
  };
  const notifyRejected = (type) => {
    toast.success(`${type} Rejected`);
  };

  const notifyFailure = () => toast.error("something went wrong");

  useEffect(() => {
    getLeavesReqData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLeavesReqData = async () => {
    setApiStatus(apiStatusConstants.inProgress);

    try {
      const response = await getPendingLeaves();
      if (response.ok) {
        const data = await response.json();
        setLeavesReqData(data);
        const updatedData = data.map((eachItem) => ({
          leaveId: eachItem.leave_id,
          days: eachItem.days,
          employeeEmail: eachItem.employee_email,
          employeeId: eachItem.employee_id,
          endDate: formatDate(eachItem.end_date),
          hrEmail: eachItem.hr_email,
          leaveReason: eachItem.leave_reason,
          rejectedReason: eachItem.rejected_reason,
          startDate: formatDate(eachItem.start_date),
          status: eachItem.status,
          firstName: eachItem.first_name,
          lastName: eachItem.last_name,
          fullName: eachItem.full_name,
          leavesUtilized:
            parseInt(eachItem.current_month_leaves) < 0
              ? parseInt(eachItem.current_month_leaves) * -1 + 1
              : parseInt(eachItem.current_month_leaves),
          requestType: eachItem.request_type,
        }));
        setLeavesReqData(updatedData);
        setApiStatus(apiStatusConstants.success);
      } else {
        setApiStatus(apiStatusConstants.failure);
      }
    } catch (e) {
      setApiStatus(apiStatusConstants.failure);
    }
  };

  const onClickApprove = (params) => {
    sendAcceptedStatus(params);
  };

  const sendAcceptedStatus = async (params) => {
    setApprovalApiStatus(apiStatusConstants.inProgress);
    const empLeaveId = params.data.leaveId;
    const leaveStatus = "Approved";
    const response = await updateLeave(leaveStatus, empLeaveId, "");
    try {
      if (response.status === 200) {
        notifyApproved(params.data.requestType);
        setTimeout(() => {
          getLeavesReqData();
          setApprovalApiStatus(apiStatusConstants.success);
        }, 500);
      } else {
        notifyFailure();
      }
    } catch (error) {
      notifyFailure();
    }
  };

  const onClickReject = (params) => {
    const rowData = params.data;
    setRowData({ ...rowData, status: "Rejected" });
    notifyRejected(params.data.requestType)
    setReject(true);
  };

  const onClickClose = () => {
    setReject(false);
  };

  const pendingLeavescolumns = [
    {
      headerName: "EMP ID",
      field: "employeeId",
      width: 120,
      pinned: "left",
      floatingFilter: true,
      filter: true,
    },
    {
      headerName: "NAME",
      field: "fullName",
      filter: true,
      sortable: true,
      pinned: "left",
      floatingFilter: true,
      editable: true,
      tooltipField: "fullName",
    },
    {
      headerName: "START DATE",
      field: "startDate",
      floatingFilter: true,
      filter: true,
    },
    {
      headerName: "END DATE",
      field: "endDate",
      floatingFilter: true,
      filter: true,
    },
    {
      headerName: "REQUEST TYPE",
      field: "requestType",
      floatingFilter: true,
      filter: true,
    },
    { headerName: "DAYS", field: "days", floatingFilter: true, filter: true },
    {
      headerName: "REASON",
      field: "leaveReason",
      tooltipField: "leaveReason",
      floatingFilter: true,
      filter: true,
    },
    {
      headerName: "LEAVES UTILIZED",
      field: "leavesUtilized",
      floatingFilter: true,
      filter: true,
    },

    {
      headerName: "APPROVE",
      cellRenderer: (params) => (
        <PrimaryButton
          disabled={approvalApiStatus === apiStatusConstants.inProgress}
          className="approve-btn d-flex  justify-content-center align-items-center"
          onClick={() => onClickApprove(params)}
        >
          Approve
        </PrimaryButton>
      ),
    },

    {
      headerName: "REJECT",
      cellRenderer: (params) => (
        <SecondaryButton
          className="reject-btn d-flex  justify-content-center align-items-center"
          onClick={() => onClickReject(params)}
        >
          Reject
        </SecondaryButton>
      ),
    },
    // {
    //   headerName: "CANCEL",
    //   cellRenderer: (params) => (
    //     <SecondaryButton
    //       className="reject-btn d-flex  justify-content-center align-items-center"
    //       onClick={() => onClickReject(params)}
    //     >
    //       Cancel
    //     </SecondaryButton> //WE NEED TO WRITE THE LOGIC FOR THIS ONE ACCORDINGLY WHICH HAS TO BE SHOWN FOR ADMIN ITSELF
    //   ),
    // },

  ];

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: { fontSize: "14px" },
    };
  }, []);

  const paginationPageSize = 20;

  const onClickRetry = () => {
    getLeavesReqData();
  };

  const leaveRequestsPage = () => (
    <>
      <div
        className="ag-theme-alpine"
        style={{
          height: "70vh",
          // width: "85vw",
          fontSize: "14px",
          marginLeft: "15px",
          marginRight: "15px",

          fontFamily: "Arial, Helvetica, sans-serif",
        }}
      >
        {/* <h4 className="mb-3 mt-3">Leave Requests</h4> */}

        <AgGridReact
          rowData={leavesReqData}
          columnDefs={pendingLeavescolumns}
          pagination={true}
          paginationPageSize={paginationPageSize}
          defaultColDef={defaultColDef}
        ></AgGridReact>
      </div>
    </>
  );

  const renderLeaveRequests = () => {
    switch (apiStatus) {
      case apiStatusConstants.inProgress:
        return <LoadingView />;
      case apiStatusConstants.success:
        return leaveRequestsPage();
      case apiStatusConstants.failure:
        return <FailureView onClickRetry={onClickRetry} />;
      default:
        return null;
    }
  };

  return (
    <>
      {renderLeaveRequests()}
      {/* <SlidingModal
        modalName="Leave Reject"
        closeBtn={onClickClose}
        isModalOpen={rejectClicked}
      >
        <LeaveRejectForm
          rowData={rowData}
          getLeavesReqData={getLeavesReqData}
          setReject={setReject}
          setRowData={setRowData}
          closeBtn={onClickClose}
        />
      </SlidingModal> */}

      <PopupModal
        modalName="Leave Reject"
        closeBtn={onClickClose}
        showModal={rejectClicked}
      >
        <LeaveRejectForm
          rowData={rowData}
          getLeavesReqData={getLeavesReqData}
          setReject={setReject}
          setRowData={setRowData}
          closeBtn={onClickClose}
        />
      </PopupModal>

      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default LeaveRequestsRoute;

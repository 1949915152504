import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  StyledInput,
  StyledInputContainer,
  StyledInputGroup,
  StyledLabel,
  StyledSelect,
  StyledSelectContainer,
  StyledSelectGroup,
} from "../../StyledCss/styledInputs";
import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";
import { addResource } from "../../api/services/hroneApi";
import "./addResourceForm.css";

const AddResourceForm = ({
  setAddResourceClicked,
  getResourceDetails,
  closeAddResource,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  console.log(errors);

  // const [activeForm, setActiveForm] = useState("employee");

  const watchedFields = watch("deviceType"); // Watch all fields by default

  const [formErrorMsg, setFormErrorMsg] = useState("");
  const notifySaved = () => toast.success("Resource added Successfully");

  const onSubmit = (data) => {
    addNewResource(data);
  };

  const addNewResource = async (data) => {
    try {
      const response = await addResource(data);
      if (response.ok) {
        setAddResourceClicked(!false);
        reset();
        notifySaved();
        closeAddResource();
        setTimeout(() => {
          getResourceDetails();
        }, 1000);
      } else {
        const data = await response.json();
        setFormErrorMsg(data.detail);
      }
    } catch (error) {}
  };

  const addResourceForEmployeeForm = () => (
    <form
      autoComplete="off"
      className="AddResource-popup-container"
      // onSubmit={addNewResource}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="add-resource-form-container">
        {/* <StyledInputGroup style={{ marginBottom: "15px" }}>
          <StyledInputContainer>
            <StyledInput
              style={errors.employeeId && { borderLeft: "10px solid red" }}
              name="employeeId"
              {...register("employeeId", {
                required: true,
                pattern: /^[a-zA-Z0-9]+.*$/,
              })}
              type="text"
            />
            <StyledLabel className="user-label">Emp Id*</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup> */}

        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              style={errors.allocatedDate && { borderLeft: "10px solid red" }}
              name="allocatedDate"
              {...register("allocatedDate", { required: true })}
              type="date"
              max="9999-12-01"
            />
            <StyledLabel className="user-label">Allocated Date*</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>

        <StyledSelectGroup style={{ marginBottom: "15px" }}>
          <StyledSelectContainer>
            <StyledSelect
              style={errors.deviceType && { borderLeft: "10px solid red" }}
              {...register("deviceType", { required: true })}
            >
              <option value="">---select---</option>
              <option>Laptop</option>
              <option>Mobile</option>
              <option>LAN</option>
              <option>Mouse</option>
              <option>Others</option>
            </StyledSelect>
            <StyledLabel className="user-label">Device Type*</StyledLabel>
          </StyledSelectContainer>
        </StyledSelectGroup>

        {watchedFields !== "LAN" && (
          <StyledInputGroup>
            <StyledInputContainer>
              <StyledInput
                style={errors.serialId && { borderLeft: "10px solid red" }}
                name="serialId"
                {...register("serialId", {
                  required: true,
                  pattern: /^(?!\s)[a-zA-Z0-9.\- ]{3,30}$/,
                })}
                type="text"
              />
              <StyledLabel className="user-label">Serial Id*</StyledLabel>
            </StyledInputContainer>
          </StyledInputGroup>
        )}

        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              style={errors.model && { borderLeft: "10px solid red" }}
              name="model"
              {...register("model", {
                required: true,
                pattern: /^[a-zA-Z0-9]+.*$/,
              })}
              type="text"
            />
            <StyledLabel className="user-label">Model*</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>

        {(watchedFields === "Others" || watchedFields === "LAN") && (
          <StyledInputGroup>
            <StyledInputContainer>
              <StyledInput
                style={errors.totalAssigned ? { borderLeft: "10px solid red" } : {}}
                defaultValue="1"
                type="number"
                {...register("totalAssigned", {
                  required: true,
                  pattern: {
                    value: /^[1-9]\d*$/,
                  },
                })}
              />
              <StyledLabel className="user-label">Count*</StyledLabel>
            </StyledInputContainer>
          </StyledInputGroup>
        )}

        {/* <StyledSelectGroup>
          <StyledSelectContainer>
            <StyledSelect
              style={errors.status && { borderLeft: "10px solid red" }}
              {...register("status", { required: true })}
            >
              <option value="">---select---</option>
              <option value="Active">Assigned</option>
              <option value="Not Assigned">Not Assigned</option>
            </StyledSelect>
            <StyledLabel className="user-label">Status*</StyledLabel>
          </StyledSelectContainer>
        </StyledSelectGroup> */}
      </div>

      {formErrorMsg.length > 0 && (
        <p style={{ color: "red", marginTop: "10px" }}>*{formErrorMsg}</p>
      )}

      <div className="add-resource-submit-btn">
        <SecondaryButton type="button" onClick={() => closeAddResource()}>
          cancel
        </SecondaryButton>
        <PrimaryButton type="submit">Submit</PrimaryButton>
      </div>
    </form>
  );

  return <>{addResourceForEmployeeForm()}</>;
};

export default AddResourceForm;

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { PrimaryButton } from "../../StyledCss/styledbtn";
import AddEmployeePopup from "../AddEmployeePopup";
import Header from "../Header";
import LeftNavBar from "../LeftNavBar";
import SlidingModal from "../SlidingModal/slidingModal";
import PopupModal from "../PopupModal";
import ActiveEmployeeTable from "../ActiveEmployeesTable";
import ReleasedEmployeeTable from "../ReleasedEmployeeTable";
import "./index.css";
import { convertKeysToCamelCase } from "../../util/convertCamelCase";
import { PageHeaderName } from "../../StyledCss/styledComponents";
// import { Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import {
  exportAllEmployees,
  getAllActiveEmployees,
  getAllReleasedEmployees,
  getLeads,
} from "../../api/services/hroneApi";
import formatDate from "../../util/formatdate";
import PageHeader from "../PageHeader";
import UploadEmployeeExcel from "../UploadEmployeesExcel/uploadEmployeeExcel";

const apiStatusConstants = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const pageTabs = [
  {
    tabName: "Active",
    tabId: "ACTIVE",
  },
  {
    tabName: "Released",
    tabId: "RELEASED",
  },
];

const AllEmployees = () => {
  const [employees, setEmployees] = useState([]);
  const [releasedEmployees, setReleasedEmployees] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);
  const [openPopupExcel, setOpenPopupExcel] = useState(false);
  const [releaseEmployeesApiStatus, setReleaseEmployeesApiStatus] = useState(
    apiStatusConstants.initial
  );
  // const [exportType, setExportType] = useState("All");
  // const [anchorEl, setAnchorEl] = useState(null);



  const [isExportDropdownOpen, setisExportDropdownOpen] = useState(false);
  // const [exportType, setExportType] = useState("all");
  const [leads, setLeads] = useState([]);
  const [activeTab, setActiveTab] = useState(pageTabs[0].tabId);

  const notifySaved = () => toast.success("Saved Successfully");

  useEffect(() => {
    fetchEmployees();
    fetchLeads();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeTab === pageTabs[1].tabId) {
      fetchReleasedEmployees();
    }
  }, [activeTab]);


  const handleCloseExportDropdown = () => {
    setisExportDropdownOpen(false);
  };


  const fetchReleasedEmployees = async () => {
    try {
      setReleaseEmployeesApiStatus(apiStatusConstants.inProgress);
      const response = await getAllReleasedEmployees();
      if (response.status === 200) {
        const data = await response.json();
        const camelCaseData = await data.map((employee) =>
          convertKeysToCamelCase(employee)
        );
        const updatedData = camelCaseData.map((eachItem) => {
          const joiningDate = formatDate(eachItem.joiningDate);
          const dateOfBirth = formatDate(eachItem.dateOfBirth);
          return { ...eachItem, joiningDate, dateOfBirth };
        });
        setReleasedEmployees(updatedData);
        setReleaseEmployeesApiStatus(apiStatusConstants.success);
      }
    } catch (error) {
      setApiStatus(apiStatusConstants.failure);
    }
  };

  const toggleIsPopupOpen = () => {
    setIsPopupOpen(false);
  };

  const UpdateEmpAdded = () => {
    notifySaved();
  };

  const fetchEmployees = async () => {
    try {
      setApiStatus(apiStatusConstants.inProgress);
      const response = await getAllActiveEmployees();
      if (response.status === 200) {
        const data = await response.json();
        const camelCaseData = await data.map((employee) =>
          convertKeysToCamelCase(employee)
        );
        const updatedData = camelCaseData.map((eachItem) => {
          const joiningDate = formatDate(eachItem.joiningDate);
          const dateOfBirth = formatDate(eachItem.dateOfBirth);
          return { ...eachItem, joiningDate, dateOfBirth };
        });
        setEmployees(updatedData);
        setApiStatus(apiStatusConstants.success);
      }
    } catch (error) {
      setApiStatus(apiStatusConstants.failure);
    }
  };

  const fetchLeads = async () => {
    try {
      const response = await getLeads();
      if (response.status === 200) {
        const data = await response.json();
        setLeads(data);
      }
    } catch (error) { }
  };

  const handleExportData = async (exportType) => {
    try {
      let response;
  
      if (exportType === "All") {
        response = await exportAllEmployees(exportType);
      } else if (exportType === "Active") {
        response = await exportAllEmployees(exportType);
      } else if (exportType === "Released") {
        response = await exportAllEmployees(exportType);
      }
  
      if (response && response.status === 200) {
        const fileNameFromServer = response.headers["content-disposition"];
        const filename = fileNameFromServer
          ? fileNameFromServer.split("filename=")[1].replace(/"/g, "").trim()
          : `${exportType}_employees.xlsx`;
  
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
  
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = filename;
        downloadLink.click();
        URL.revokeObjectURL(downloadLink.href);
        handleCloseExportDropdown();
      } else {
        console.error("Failed to export data: ", response);
      }
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };
  


  const onClickAddEmployee = () => {
    setIsPopupOpen(true);
  };

  const onCloseExcelModal = () => {
    setOpenPopupExcel(false);
  };

  const refreshEmployeesList = () => {
    fetchEmployees();
  };

  const displayActiveTabTable = () => {
    switch (activeTab) {
      case "ACTIVE":
        return (
          <ActiveEmployeeTable
            employeesList={employees}
            apiStatus={apiStatus}
            refreshEmployeesList={refreshEmployeesList}
          />
        );
      case "RELEASED":
        return (
          <ReleasedEmployeeTable
            employeesList={releasedEmployees}
            apiStatus={releaseEmployeesApiStatus}
            refreshEmployeesList={fetchReleasedEmployees}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Header />
      <div className="d-flex flex-column  mt-0 all-employees-container">
        <div className="d-flex">
          <LeftNavBar />
          <div className="all-employees-page">
            <PageHeader>
              <PageHeaderName>Employees</PageHeaderName>
              <div
                style={{ marginRight: "20px" }}
                className="text-end d-flex align-items-center"
              >
                <PrimaryButton
                  width="150px"
                  style={{ marginLeft: "10px" }}
                  onClick={() => setOpenPopupExcel(!openPopupExcel)}
                >
                  Import Excel Sheet
                </PrimaryButton>
                <div className="dropdown" style={{ position: "relative" }}>
                  <PrimaryButton
                    className="dropdown-toggle"
                    type="button"
                    onClick={() => {
                      setisExportDropdownOpen(!isExportDropdownOpen);
                    }}
                  >
                    Export Data
                  </PrimaryButton>
                  {isExportDropdownOpen && (
                    <ul className={`dropdown-menu ${isExportDropdownOpen ? 'open' : ''}`}>
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() => handleExportData("All")}
                        >
                          All Employees
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() => handleExportData("Active")}
                        >
                          Active Employees
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() => handleExportData("Released")}
                        >
                          Released Employees
                        </button>
                      </li>
                    </ul>
                  )}
                </div>




                <PrimaryButton
                  style={{ marginLeft: "10px" }}
                  type="button"

                  onClick={onClickAddEmployee}
                >
                  Add Employee
                </PrimaryButton>

                {/* {BasicExample()} */}
              </div>
            </PageHeader>

            <div className="table-container">
              <ul style={{ listStyleType: "none" }} className="d-flex">
                {pageTabs.map((eachItem) => (
                  <li className="me-5" key={eachItem.tabId}>
                    <button
                      className={
                        activeTab === eachItem.tabId
                          ? "activeTabButton"
                          : "tabButtons"
                      }
                      // className="tabButtons"
                      onClick={() => setActiveTab(eachItem.tabId)}
                    >
                      {eachItem.tabName}
                    </button>
                  </li>
                ))}
              </ul>

              {displayActiveTabTable()}
            </div>
          </div>
        </div>



        <SlidingModal
          modalName="Add Employee"
          closeBtn={toggleIsPopupOpen}
          isModalOpen={isPopupOpen}
        >
          <AddEmployeePopup
            fetchEmployees={fetchEmployees}
            popupOpen={isPopupOpen}
            toggleIsPopupOpen={toggleIsPopupOpen}
            UpdateEmpAdded={UpdateEmpAdded}
            closeBtn={toggleIsPopupOpen}
            leads={leads}
          />
        </SlidingModal>

        <PopupModal
          modalName="Upload File"
          closeBtn={onCloseExcelModal}
          showModal={openPopupExcel}
        >
          <UploadEmployeeExcel
            fetchEmployees={fetchEmployees}
            setOpenPopupExcel={setOpenPopupExcel}
          />
        </PopupModal>
        <Toaster position="top-center" reverseOrder={false} />
      </div>
    </>
  );
};

export default AllEmployees;

/* eslint-disable react-hooks/exhaustive-deps */
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { getDate, getMonth, getYear } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";

import jwt_decode from "jwt-decode";

import {
  exportResources,
  getResource,
  releaseResource,
  assignResource
} from "../../api/services/hroneApi";

import formatDate from "../../util/formatdate";
import AddResourceForm from "../AddResourceForm/addResourceForm";
import FailureView from "../FailureView";
import Header from "../Header";
import LeftNavBar from "../LeftNavBar";
// import LoadingView from "../LoadingView";
import { useForm } from "react-hook-form";
import { ErrorText, PageHeaderName } from "../../StyledCss/styledComponents";
// import ExcessOrReleasedResources from "../ExcessOrReleasedResorces/index";
import PageHeader from "../PageHeader";
import ProfileDetailsTabs from "../ProfileDetailsTabs";
import ResourceTrackingSummary from "../ResourceTrackingSummary/resourceTrackingSummary";
import SlidingModal from "../SlidingModal/slidingModal";
import UploadResourcesForm from "../UploadResourceExcel/uploadresoucesForm";
import "./index.css";

import {
  StyledInput,
  StyledInputContainer,
  StyledInputGroup,
  StyledLabel,
  StyledSelect,
  StyledSelectContainer,
  StyledSelectGroup,
} from "../../StyledCss/styledInputs";
import PopupModal from "../PopupModal";
import ResourceTrackingHistory from "../ResourceTrackingHistory";
import LoadingView from "../LoadingView";
const apiStatusConstants = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const tabData = [
  // { id: "RELEASED-EXCESS", tabName: "Resources" },
  { id: "SUMMARY", tabName: "Summary" },
  { id: "ALL", tabName: "Resources" },
  { id: "TRACKING-HISTORY", tabName: "Tracking History" },
];

const ResourceTracking = () => {
  const [activeTrackingGadget, setActiveTrackingGadget] = useState("All");
  const [addResourceClicked, setAddResourceClicked] = useState(false);
  const [resourcesData, setResourceData] = useState([]);
  const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);
  const [openPopupExcel, setOpenPopupExcel] = useState(false);
  const [deviceCount, setDeviceCount] = useState();
  const [activeTabName, setActiveTab] = useState(tabData[0].id);
  const [releaseData, setReleaseData] = useState({});

  const [rowData, setRowData] = useState({});
  const [currentDay, setCurrentDay] = useState("");

  const notifyReleased = () => toast.success("Resource Released Successfully");



  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const empIdField = watch("releasedToID");
  const countValue = watch("count");

  useEffect(() => {
    // setReleaseFormError(""); // Reset releaseFormError to an empty string

    const date = new Date();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    const year = date.getFullYear();

    if (month < 10) {
      month = `0${month}`;
    }

    if (day < 10) {
      day = `0${day}`;
    }

    const formattedDate = `${year}-${month}-${day}`;
    setCurrentDay(formattedDate);
  }, [empIdField]);

  useEffect(() => {
    if (activeTabName === "ALL") {
      getResourceDetails();
    }
  }, [activeTabName, activeTrackingGadget]);

  const handleExportResources = async () => {
    try {
      const response = await exportResources(activeTrackingGadget);
      if (response.status === 200) {
        setOpenPopupExcel(false);
  
        const fileNameFromServer = response.headers["content-disposition"];
        const filename = fileNameFromServer
          ? fileNameFromServer.split("filename=")[1].replace(/"/g, "").trim()
          : "resources.xlsx";
  
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
  
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = filename;
        downloadLink.click();
        URL.revokeObjectURL(downloadLink.href);
      }
    } catch (error) {
      console.error("Error while exporting resources:", error);
    }
  };
  

 


  const closeAddResource = () => {
    setAddResourceClicked(false);
  };

  const onCloseExcelModal = () => {
    setOpenPopupExcel(false);
  };

  const getResourceDetails = async () => {
    setApiStatus(apiStatusConstants.inProgress);

    try {
      const response = await getResource(activeTrackingGadget);
      if (response.ok) {
        const data = await response.json();

        const activeDeviceData = data.filter(
          (eachItem) =>
            eachItem.status === "Assigned" || eachItem.status === "Not Assigned"
        );

        const updatedData = activeDeviceData.map((eachItem) => ({
          employeeId: eachItem.employee_id,
          firstName: eachItem.first_name,
          lastName: eachItem.last_name,
          deviceType: eachItem.device_type,
          model: eachItem.model,
          serialId: eachItem.serial_id,
          type: eachItem.type,
          count: eachItem.count,
          assignedDate: formatDate(eachItem.assigned_date),
          releasedDate:
            eachItem.released_date !== null
              ? formatDate(eachItem.released_date)
              : "",
          status: eachItem.status,
          resourceID: eachItem.resource_id,
        }));

        // console.log("updatedData",updatedData)
        setDeviceCount(updatedData.length);
        setResourceData(updatedData);
        setApiStatus(apiStatusConstants.success);
      }
    } catch (error) {
      setApiStatus(apiStatusConstants.failure);
    }
  };



  const showActionButton = (params) => {
    const { status } = params.data;

    return status === "Assigned" ? (
      <SecondaryButton
        className="reject-btn d-flex justify-content-center align-items-center"
        onClick={() => {
          onClickRelease(params.data)
          

        }}
      >
        Release
      </SecondaryButton>
    ) : (
      <SecondaryButton
        onClick={() => handleAssignResource(params)}
        className="assign-btn d-flex justify-content-center align-items-center"
      >
        Assign
      </SecondaryButton>
    );
  };


  const onChangeGadgets = (e) => {
    setActiveTrackingGadget(e.target.value);
  };



  const onClickRelease = async (releaseData) => {
    // console.log(releaseData)
    const Presentdate = new Date();
    const date = getDate(Presentdate);
    const month = getMonth(Presentdate) + 1;
    const year = getYear(Presentdate);
    let currentMonth = null;
    let currentDate = null;

    if (month < 10) {
      currentMonth = `0${month}`;
    } else {
      currentMonth = month;
    }

    if (date < 10) {
      currentDate = `0${date}`;
    } else {
      currentDate = date;
    }

    const releasedate = `${year}-${currentMonth}-${currentDate}`;
    const resourceData = {
      employee_id:releaseData.employee_id,
      released_by_id: getLoggedInUserId(),
      resource_id: releaseData.resourceID,
      released_date: releasedate
    };
    try {
      const response = await releaseResource(resourceData);

      if (response.status === 200) {
        getResourceDetails()
        setReleaseData(true)
        notifyReleased()
      }
    } catch (error) {
      console.log(error);
    }




  };

  const onSubmit = (data) => {
    assignResourceToEmployee(data);
  };


  const getLoggedInUserId = (() => {
    const loggedInUserDetails = localStorage.getItem("loginDetails")
    const userObject = jwt_decode(loggedInUserDetails);
    return userObject.employee_id
  })

  const assignResourceToEmployee = async (data) => {
   
    
    const resourceData = {
      resource_id: rowData.resourceID,
      assigned_to_id: data.employeeId,
      assigned_from_id: getLoggedInUserId(),
      assigned_date: data.allocatedDate, 

    };
    try {
      const response = await assignResource(resourceData);

      if (response.status === 200) {
        setAssignClicked(false);
        getResourceDetails()
        reset();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const assignResourceForm = () => (
    <form
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      className="d-flex assign-resource-form gap-3"
    >
      <StyledInputGroup>
        <StyledInputContainer>
          <StyledInput
            style={errors.employeeId && { borderLeft: "10px solid red" }}
            name="employeeId"
            type="text"
            {...register("employeeId", {
              required: true,
              pattern: /^(?!\s)[a-zA-Z0-9]{3,10}$/,
            })}
          />
          <StyledLabel className="user-label">Emp ID*</StyledLabel>
        </StyledInputContainer>
      </StyledInputGroup>



      <StyledInputGroup>
        <StyledInputContainer>
          <StyledInput
            style={errors.allocatedDate && { borderLeft: "10px solid red" }}

            name="allocatedDate"

            max={currentDay}
            type="date"
            {...register("allocatedDate", {
              required: true,
            })}
          />
          <StyledLabel hasValue="true" className="user-label">
            Date*
          </StyledLabel>
        </StyledInputContainer>
      </StyledInputGroup>

      <StyledInputGroup>
        <StyledInputContainer>
          <StyledInput readOnly value={rowData.deviceType} type="text" />
          <StyledLabel hasValue={!!rowData.deviceType}>Device Type*</StyledLabel>

        </StyledInputContainer>
      </StyledInputGroup>

      
      <StyledInputGroup>
        <StyledInputContainer>
          <StyledInput readOnly value={rowData.model} type="text" />
          <StyledLabel hasValue={rowData.model !== ""} className="user-label">
            Device Model*
          </StyledLabel>
        </StyledInputContainer>
      </StyledInputGroup>

      {rowData.deviceType !== "LAN" && (
        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput readOnly value={rowData.serialId} type="text" />
            <StyledLabel
              hasValue={rowData.serialId !== ""}
              className="user-label"
            >
              Serial ID*
            </StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>
      )}

      {countValue && (countValue > rowData.count || countValue < 1) && (
        <ErrorText style={{ marginTop: "10px" }}>
          *Count should be less than or equal to a {rowData.count}
        </ErrorText>
      )}

      {errors.count && (
        <ErrorText style={{ marginTop: "10px" }}>
          *Count should be less than or equal to {rowData.count}
        </ErrorText>
      )}

      <div className="d-flex w-100 justify-content-end gap-2 d-block me-5">
        <SecondaryButton onClick={closeModal} type="button">
          Cancel
        </SecondaryButton>
        <PrimaryButton type="submit" disabled={releaseButtonDisabled}>
          Submit
        </PrimaryButton>
      </div>
    </form>
  );




  const handleAssignResource = (params) => {
    setAssignClicked(true);
    setRowData(params.data);
  };

  const [assignClicked, setAssignClicked] = useState(false);



  const releaseButtonDisabled =

    countValue &&
    (countValue > releaseData.data.count ||
      countValue < 1 ||
      countValue === "");

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: { fontSize: "14px" },
    };
  }, []);

  const renderTable = () => {
    const paginationPageSize = 50;
    const columnDefs = [
      {
        headerName: "DEVICE TYPE",
        field: "deviceType",
        filter: true,
        floatingFilter: true,
      },
      {
        headerName: "MODEL",
        field: "model",
        width: 130,
        filter: true,
        floatingFilter: true,
      },

      {
        headerName: "SERIAL ID",
        field: "serialId",
        width: 200,
        filter: true,
        floatingFilter: true,
        tooltipField: "serialId",
        editable: true,
      },

      {
        headerName: "STATUS",
        field: "status",
        width: 150,
        filter: true,
        floatingFilter: true,
      },

      {
        headerName: "ACTION",
        cellRenderer: (params) => showActionButton(params),
      },
    ];

    return (
      <div
        className="ag-theme-alpine"
        style={{
          height: "70vh",
          fontSize: "14px",
        }}
      >
        <AgGridReact
          title="All Resources"
          columnDefs={columnDefs}
          rowData={resourcesData}
          pagination={true}
          paginationPageSize={paginationPageSize}
          defaultColDef={defaultColDef}
        ></AgGridReact>
      </div>
    );
  };

  const onClickRetry = () => {
    getResourceDetails();
  };
  const closeModal = () => {
    setAssignClicked(false);
    reset();
  };

  const resourceTracking = () => {
    switch (apiStatus) {
      case apiStatusConstants.inProgress:
        return <LoadingView />;
      case apiStatusConstants.success:
        return renderTable();
      case apiStatusConstants.failure:
        return <FailureView onClickRetry={onClickRetry} />;
      default:
        return null;
    }
  };

  const renderTabs = () => (
    <ul style={{ listStyleType: "none" }} className="d-flex">
      {tabData.map((eachItem) => (
        
        <ProfileDetailsTabs
          tabData={eachItem}
          activeTab={eachItem.id === activeTabName}
          setActiveTab={setActiveTab}
        />

      ))}
    </ul>
  );

  const renderActiveTabDetails = () => {
    switch (activeTabName) {
      case "ALL":
        return resourceTracking();
      case "SUMMARY":
        return <ResourceTrackingSummary />;

      case "TRACKING-HISTORY":
        return <ResourceTrackingHistory />;
      default:
        break;
    }
  };

  // const closePopUpModal = () => {
  //   // setReleaseClicked(false);
  //   reset();
  // };

  return (
    <div>
      <Header />
      <div className="d-flex">
        <LeftNavBar />
        <div className="resourceTrackingContainer" style={{ width: "100%" }}>
          <PageHeader>
            <PageHeaderName>Resource Tracking</PageHeaderName>
            <div>
              <PrimaryButton
                style={{ marginLeft: "15px" }}
                type="button"
                onClick={() => setAddResourceClicked(true)}
              >
                Add Resource
              </PrimaryButton>

              <PrimaryButton
                width="150px"
                style={{ marginLeft: "10px", marginRight: "10px" }}
                onClick={() => setOpenPopupExcel(!openPopupExcel)}
              >
                Import Excel Sheet
              </PrimaryButton>
              <PrimaryButton
                style={{ marginRight: "20px" }}
                type="button"
                onClick={handleExportResources}
              >
                Export Resource
              </PrimaryButton>
            </div>
          </PageHeader>
          <div className="resource-tracking-container">
            <div className="d-flex justify-content-between">
              {renderTabs()}

              {activeTabName === "ALL" && (
                <div className="d-flex align-items-center justify-content-end me-5 mb-3">
                  <StyledSelectGroup>
                    <StyledSelectContainer>
                      <StyledSelect
                        width="150px"
                        value={activeTrackingGadget}
                        onChange={onChangeGadgets}
                      >
                        <option value="All">All </option>
                        <option value="Laptop">Laptop </option>
                        <option value="Mobile">Mobile </option>
                        <option value="Mouse">Mouse </option>
                        <option value="LAN">LAN </option>
                        <option value="Others">Others </option>
                      </StyledSelect>
                      <StyledLabel className="user-label">
                        Device Type
                      </StyledLabel>
                    </StyledSelectContainer>
                  </StyledSelectGroup>

                  <div
                    style={{ border: `1px solid #000000` }}
                    className="resourceCount"
                  >
                    <p>{deviceCount}</p>
                  </div>
                </div>
              )}
            </div>
            {renderActiveTabDetails()}
          </div>

          {/* {resourceTracking()} */}
        </div>

        <SlidingModal
          modalName="Add Resource"
          closeBtn={closeAddResource}
          isModalOpen={addResourceClicked}
        >
          <AddResourceForm
            closeAddResource={closeAddResource}
            setAddResourceClicked={setAddResourceClicked}
            getResourceDetails={getResourceDetails}
          />
        </SlidingModal>

        <PopupModal
          modalName="Upload File"
          closeBtn={onCloseExcelModal}
          showModal={openPopupExcel}
        >
          <UploadResourcesForm setOpenPopupExcel={setOpenPopupExcel} />
        </PopupModal>

        <PopupModal
          modalName="Assign To"
          closeBtn={closeModal}
          showModal={assignClicked}
        >
          {assignResourceForm()}
        </PopupModal>

        <Toaster position="top-center" reverseOrder={false} />
      </div>
    </div>
  );
};

export default ResourceTracking;

import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup"; // Import Yup for form validation
import { ErrorText } from "../../StyledCss/styledComponents";
import "./enterEmail.css";

import jwt_decode from "jwt-decode";
import { Navigate, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import {
  CardHeadings,
  CardText,
  // Input,
  // Label,
} from "../../StyledCss/styledComponents";
import { verifyUser } from "../../api/services/hroneApi";
import {
  StyledInput,
  StyledInputContainer,
  StyledInputGroup,
  StyledLabel,
} from "../../StyledCss/styledInputs";
const EnterEmail = () => {
  const navigate = useNavigate(); 
  const userResetToken = JSON.parse(sessionStorage.getItem("userResetToken"));
  const { email_validated } = userResetToken
    ? jwt_decode(userResetToken)
    : () => {};

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("*Required"),
  });

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const userObj = {
          employee_email: values.email,
          keyword: "forgotPassword",
        };
        const response = await verifyUser(userObj);
        if (response.status === 200) {

          navigate("/verifyOTP");
        } else {
          setError(response.data.message);
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
        setError(err.response.data.detail);
      }
    },
  });

  const image = process.env.PUBLIC_URL + "/forgotpassword.jpg";
  const companyLogo = process.env.PUBLIC_URL + "/urbuddilogo.png";
  return email_validated ? (
    <Navigate to="/verifyOTP" />
  ) : (
    <div className="forgot-password-container d-flex align-items-center">
      <img style={{ height: "70%" }} src={image} alt="forgotPasswordVector" />
      <div className="forgot-div">
        <div
          className="emailVerificationContainer"
        >
          <div className="p-3">
            <img className="company-logo" src={companyLogo} alt="companyLogo" />
            <CardHeadings className="forgot-header">
              Forgot Password
            </CardHeadings>
            <CardText className="">
              Please enter your registered email address, to get the OTP
            </CardText>

            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <StyledInputGroup>
                <StyledInputContainer>
                  <StyledInput
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    type="email"
                    name="email"
                  />
                  <StyledLabel hasValue={true} className="user-label">
                    Email*
                  </StyledLabel>
                </StyledInputContainer>
              </StyledInputGroup>

              {formik.touched.email && formik.errors.email && (
                <p className="errorText">{formik.errors.email}</p>
              )}

              {error ? <ErrorText className="mt-2">*{error}</ErrorText> : null}

              <div className="d-flex justify-content-center mt-3">
                {isLoading ? (
                  <ClipLoader color="#cb7c25" />
                ) : (
                  <div className="w-100">
                    <button className="generateOtp" type="submit">
                      Generate OTP
                    </button>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterEmail;

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { MdDeleteOutline } from "react-icons/md";
import { RiPencilLine } from "react-icons/ri";
import { GiSkills } from "react-icons/gi";
import { Button } from "../../StyledCss/styledbtn";
import FailureView from "../FailureView";
import LoadingView from "../LoadingView";
import SlidingModal from "../SlidingModal/slidingModal";
// import { ImProfile } from "react-icons/im";
// import ZoomInModal from "../ZoomModal";
import "./index.css";

import { deleteUser, getLeads } from "../../api/services/hroneApi";
import EditEmployeeModal from "../EditEmployeeModal";

const apiStatusConstants = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const ActiveEmployeeTable = ({
  employeesList,
  apiStatus,
  refreshEmployeesList,
}) => {
  const [singleEmployeeData, setSingleEmployeeData] = useState({});
  const [editEmployeeDetailsModal, setEditEmployeeDetailsModal] =
    useState(false);
  // const [profileModalOpen, setProfileModalOpen] = useState(false); // Modal state
  // const [profileData, setProfileData] = useState({}); // Store profile data
  const [hoveredProfile, setHoveredProfile] = useState({
    isVisible: false,
    x: 0,
    y: 0,
    data: null,
  });


  const [deleteWarningModal, setDeleteWarningModal] = useState(false);
  const [deleteUserName, setDeleteUserName] = useState("");
  const [gridApi, setGridApi] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [leads, setLeads] = useState([]);
  const notifyUpdated = () => toast.success("Employee Updated Successfully");
  const notifyDelete = () => toast.success("Employee Deleted Successfully");
  const [searchTerm, setSearchTerm] = useState("");
  const [skillsModalOpen, setSkillsModalOpen] = useState(false); // Modal state
  const [employeeSkills, setEmployeeSkills] = useState([]); // Store skills

  // Fetch skills for a specific employee


  // Open skills modal and fetch skills
  const openSkillsModal = (empId) => {
    const employee = employeesList.find((emp) => emp.id === empId);
    setEmployeeSkills(employee?.skills || []); // Set skills data
    setSingleEmployeeData(employee); // Set employee data
    setSkillsModalOpen(true); // Open the modal
  };

  const handleProfileHover = (event, empId) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const employee = employeesList.find((emp) => emp.id === empId);
    if (employee) {
      setHoveredProfile({
        isVisible: true,
        x: rect.left,
        y: rect.top,
        data: {
          fullName: employee.fullName,
          designation: employee.designation,
          image: employee.profilePictureUrl || "default-image.jpg",
        },
      });
    }
  };
  const handleProfileLeave = () => {
    setHoveredProfile({ isVisible: false, x: 0, y: 0, data: null });
  };


 



  const closeSkillsModal = () => {
    setSkillsModalOpen(false); // Close the modal
  };

  const filteredSkills = employeeSkills.filter((skill) =>
    skill.skill_name && skill.skill_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    setSelectedRows([]);
    fetchLeads();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeEditEmployeeModel = () => {
    setEditEmployeeDetailsModal(false);
  };

  const fetchLeads = async () => {
    try {
      const response = await getLeads();
      if (response.status === 200) {
        const data = await response.json();
        setLeads(data);
      }
    } catch (error) { }
  };

  const noDataDisplay = () => (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <img
        className="noData-img"
        alt="NoData"
        src="https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?w=740&t=st=1685691455~exp=1685692055~hmac=e7fe93b465ad04cefb40b139444400f1cb0f9068a880f91999b843299068fdca"
      />

      <h3>No Records Found</h3>
    </div>
  );

  const deleteEmployee = async () => {
    try {
      const response = await deleteUser(selectedRows);
      if (response.status === 200) {
        setSelectedRows([]);
        notifyDelete();
        refreshEmployeesList();
        setDeleteWarningModal(false);
      }
    } catch (error) { }
  };

  const deleteEmployeeWarningModal = () => (
    <div className="popup-overlay">
      <div className="delete-employee-modal">
        <h4>Delete</h4>
        <hr />
        <h5 className="text-center">
          Are you sure you want to delete <b>{deleteUserName}</b> ?
        </h5>
        <div style={{ marginTop: "30px" }} className="text-center">
          <Button
            style={{ width: "150px" }}
            onClick={() => {
              setDeleteWarningModal(false);
              setDeleteUserName("");
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => deleteEmployee()}
            style={{
              width: "150px",
              marginLeft: "15px",
              backgroundColor: "#00adee",
            }}
          >
            Delete
          </Button>
        </div>
      </div>
    </div>
  );

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: { fontSize: "14px" },
    };
  }, []);

  const paginationPageSize = 20;

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onSelectionChanged = () => {
    if (gridApi) {
      const selectedNodes = gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => node.data.id);
      setSelectedRows(selectedData);
    }
  };

  const columnDefs = [
    {
      headerName: "", // Empty header for checkbox column
      checkboxSelection: true, // Enable checkbox selection
      headerCheckboxSelection: true, // Show header checkbox to select all rows
      width: 50, // Set the width of the checkbox column
      resizable: false,
      pinned: "left",
    },
    {
      headerName: "",
      width: 60,
      pinned: "left",
      cellRenderer: (params) => (
        <>
          <RiPencilLine
            id={params.data.id}
            onClick={() => {
              setSingleEmployeeData(params.data);
              setEditEmployeeDetailsModal(true);
            }}
            style={{ fontSize: "20px", cursor: "pointer" }}
          />
        </>
      ),
    },
    {
      headerName: "",
      width: 60,
      pinned: "left",
      cellRenderer: (params) => (
        <>
          <GiSkills
            id={params.data.id}
            onClick={() => {
              openSkillsModal(params.data.id)

            }}
            style={{ fontSize: "20px", cursor: "pointer" }}
          />
        </>
      ),
    },
    {
      headerName: "EMP ID",
      field: "id",
      width: 150,
      sortable: true,
      pinned: "left",
      floatingFilter: true,
      filter: true,
      editable: true,
    },
    {
      headerName: "",
      width: 60,
      pinned: "left",
      cellRenderer: (params) => (
        <div
          id={params.data.id}
          onClick={(e) => handleProfileHover(e, params.data.id)}
          onMouseLeave={handleProfileLeave}
          className="profile-box"
        >
          <img
            src={params.data.profilePictureUrl}
            alt="Profile"
            className="profile-image"
          />
        </div>
      ),
    },



    {
      headerName: "NAME",
      cellRenderer: (params) => {
        return <p>{params.data.fullName}</p>;
      },
      field: "fullName",
      pinned: "left",
      tooltipField: "fullName",
      filter: true,
      floatingFilter: true,
      editable: true,
    },

    {
      headerName: "EMAIL",
      field: "email",
      width: 350,
      filter: true,
      floatingFilter: true,
      tooltipField: "email",
      editable: true,
    },
    {
      headerName: "ROLE",
      field: "role",
      width: 130,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "DESIGNATION",
      field: "designation",
      width: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "DEPARTMENT",
      field: "department",
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "QUALIFICATIONS",
      field: "qualifications",
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "JOINING DATE",
      field: "joiningDate",
      width: 180,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "EMP STATUS",
      field: "employementStatus",
      width: 180,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "DOB",
      field: "dateOfBirth",
      width: 120,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "GENDER",
      field: "gender",
      width: 150,
      cellStyle: { display: "flex", justifyContent: "center" },
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "BLOOD GROUP",
      field: "bloodGroup",
      width: 180,
      cellStyle: { display: "flex", justifyContent: "center" },
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "MOBILE NO",
      field: "mobileNumber",
      width: 150,
      filter: true,
      floatingFilter: true,
      editable: true,
    },
    {
      headerName: "EMG CONTACT NAME",
      field: "emergencyContactName",
      width: 250,
      filter: true,
      floatingFilter: true,
    },

    {
      headerName: "EMG CONTACT NUMBER",
      field: "emergencyContactNumber",
      width: 250,
      filter: true,
      floatingFilter: true,
      editable: true,
    },
    {
      headerName: "LOCATION",
      field: "address",
      filter: true,
      floatingFilter: true,
    },
  ];

  const renderTable = () => (
    <div
      className="ag-theme-alpine"
      style={{
        height: "78vh",
        fontSize: "14px",
        marginLeft: "20px",
        marginRight: "20px",
        fontFamily: "Arial, Helvetica, sans-serif",
        backgroundColor: "#FFFFFF",
      }}
    >
      <AgGridReact
        title="All Employees"
        columnDefs={columnDefs}
        rowData={employeesList}
        pagination={true}
        paginationPageSize={paginationPageSize}
        defaultColDef={defaultColDef}
        rowSelection="multiple"
        onGridReady={onGridReady}
        onSelectionChanged={onSelectionChanged}
        suppressRowClickSelection="true"
      ></AgGridReact>
    </div>
  );

  const renderEmployeeTable = () => (
    <div className="table-container">
      {employeesList.length > 0 ? renderTable() : noDataDisplay()}
    </div>
  );

  const onClickRetry = () => refreshEmployeesList();

  const renderAllEmployeesPage = () => {
    switch (apiStatus) {
      case apiStatusConstants.success:
        return renderEmployeeTable();
      case apiStatusConstants.failure:
        return <FailureView onClickRetry={onClickRetry} />;
      case apiStatusConstants.inProgress:
        return <LoadingView />;
      default:
        return null;
    }
  };

  return (
    <>
      <div>
        {selectedRows.length > 0 && (
          <div className="d-flex align-items-center deleteEmployeesContainer">
            <p className="pt-3">{selectedRows.length} Employees Selected</p>
            <button className="deleteIcon" onClick={deleteEmployee}>
              <MdDeleteOutline />
            </button>
          </div>
        )}

        {renderAllEmployeesPage()}
      </div>
      <SlidingModal
        modalName={`Skills - ${singleEmployeeData.fullName}`}
        closeBtn={closeSkillsModal}
        isModalOpen={skillsModalOpen}
      >
        <div className="skills-modal-content">
          <input
            type="text"
            placeholder="Search skills..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value.trim())}
            className="skills-search-bar"
          />

          <ul className="skills-list-container">
            {(filteredSkills.length === 0) ? (
              <li>No skills available</li>
            ) : (
              filteredSkills.map((skill, index) => {
                const rating = parseInt(skill.skill_rating) * 10;
                const ratingColor = rating > 30 ? "bg-success" : "bg-danger";
                return (
                  <div key={index}>
                    <li className="skills-list-item">
                      <div className="d-flex justify-content-between">
                        <p>{skill.skill_name}</p>
                        <p>{rating} %</p>
                      </div>
                      <div className="progress">
                        <div
                          className={`progress-bar ${ratingColor}`}
                          role="progressbar"
                          style={{ width: `${rating}%` }}
                          aria-valuenow={rating}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </li>
                  </div>
                );
              })
            )}
          </ul>
        </div>
      </SlidingModal>

      {hoveredProfile.isVisible && hoveredProfile.data && (
        <div
          className="profile-hover-box"
          style={{
            top: hoveredProfile.y + 60, // Adjust position slightly below the profile box
            left: hoveredProfile.x,
          }}
        >
          <img
            src={hoveredProfile.data.image}
            alt="Employee"
            className="hover-profile-image"
          />
         <div className="profileAndDesignationStyle">
         <h3 className="hover-profile-name">{hoveredProfile.data.fullName}</h3>
          <p className="hover-profile-designation">
            {hoveredProfile.data.designation}
          </p>
         </div>
        </div>
      )}





      <SlidingModal
        modalName="Edit Employee"
        closeBtn={closeEditEmployeeModel}
        isModalOpen={editEmployeeDetailsModal}
      >
        <EditEmployeeModal
          fetchEmployees={refreshEmployeesList}
          notifyUpdated={notifyUpdated}
          singleEmployeeData={singleEmployeeData}
          closeEditEmployeeModel={closeEditEmployeeModel}
          leads={leads}
        />
      </SlidingModal>
      {deleteWarningModal && deleteEmployeeWarningModal()}
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default ActiveEmployeeTable;

import { React, useEffect, useRef, useState } from "react";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { BsFillFileEarmarkTextFill, BsX } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { FaFileInvoiceDollar, FaHandHoldingUsd } from "react-icons/fa";
import { FiLogOut, FiSettings } from "react-icons/fi";
import { GiMoneyStack, GiPayMoney } from "react-icons/gi";
import { HiOutlineBriefcase } from "react-icons/hi";
import { MdDashboard, MdDevices, MdGroups, MdHomeWork } from "react-icons/md";
import { Link } from "react-router-dom";
import { GrAruba } from "react-icons/gr";
import { CSSTransition } from "react-transition-group";
import { LeftMobNavbar } from "../../StyledCss/mobileNavbar";
// import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { deleteToken, getMessaging } from "firebase/messaging";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteDeviceToken, getUserData } from "../../api/services/hroneApi";
import {
  closeMobileMenu,
  collapseLeftnavBar,
  expandLeftnavBar,
} from "../../redux/slices/mobileSlice";
import NavBarItems from "../NavBarItems";
import PopupModal from "../PopupModal";

import "./index.css";
import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";
const LeftNavBar = () => {
  const [logoutClicked, setLogoutClicked] = useState(false);

  const menuClicked = useSelector(
    (state) => state.mobile.isLeftNavBarCollapsed
  );
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const allEmployees = "allEmployees";

  const orgDetails = JSON.parse(localStorage.getItem("org"));
  const { primaryColor } = orgDetails;

  const onClickMenu = () => {
    if (menuClicked) {
      dispatch(expandLeftnavBar());
    } else {
      dispatch(collapseLeftnavBar());
    }
  };
  const { empId } = getUserData();

  const navItems = [
    {
      id: 1,
      icon: <MdDashboard />,
      navItemName: "Dashboard",
      path: "/",
      roles: ["Super Admin", "Admin", "Employee", "HR", "Lead"],
    },

    {
      id: 3,
      icon: <MdGroups />,
      navItemName: "Employees",
      path: "/allemployees",
      roles: ["Super Admin", "Admin", "HR"],
    },
    {
      id: 4,
      icon: <HiOutlineBriefcase />,
      navItemName: "Leave Management",
      path: "/leave_management",
      roles: ["Super Admin", "Admin", "Employee", "HR", "Lead"],
    },
    // { id: 5, icon: <SlEnvelopeOpen />, navItemName: "Leaves", path: "/leave",roles:["Admin","Employee","HR","Lead"] },

    {
      id: 6,
      icon: <FaHandHoldingUsd />,
      navItemName: "Salary Management",
      path: "/salary",
      roles: ["Super Admin", "Admin"],
    },
    {
      id: 7,
      icon: <FaFileInvoiceDollar />,
      navItemName: "Payslip",
      path: `/payslip/${empId}`,
      roles: ["Super Admin", "Admin", "Employee", "HR", "Lead"],
    },

    {
      id: 8,
      icon: <MdDevices />,
      navItemName: "Resource Tracking",
      path: "/resource_tracking",
      roles: ["Super Admin", "Admin", "HR"],
    },

    {
      id: 9,
      icon: <GiPayMoney />,
      navItemName: "Reimbursement",
      path: "/reimbursement",
      roles: ["Super Admin", "Admin", "Employee", "HR", "Lead"],
    },
    {
      id: 10,
      icon: <GiMoneyStack />,
      navItemName: "Expenditure",
      path: "/expenditure",
      roles: ["Super Admin", "Admin", "HR"],
    },
    {
      id: 11,
      icon: <BsFillFileEarmarkTextFill />,
      navItemName: "Policies",
      path: "/policies",
      roles: ["Super Admin", "Admin", "HR", "Employee", "Lead"],
    },

    {
      id: 12,
      icon: <MdHomeWork />,
      navItemName: "Tenants",
      path: "/tenant",
      roles: ["Super Admin"],
    },
  ];

  const bottomNavItems = [
    {
      id: 13,
      icon: <CgProfile />,
      navItemName: "Your Profile",
      path: "/profile",
      roles: ["Super Admin", "Admin", "Employee", "HR", "Lead"],
    },
    {
      id: 14,
      icon: <GrAruba className="react-icon"/>,
      navItemName: "Leave Overview",
      path: "/LeavesOverview",
      roles: ["Super Admin", "Admin", "HR", "Lead"],
    },

    {
      id: 15,
      icon: <FiSettings />,
      navItemName: "Settings",
      path: "/settings",
      roles: ["Super Admin", "Admin"],
    },
  ];

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    document.addEventListener("keydown", handleEscapeKeyClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("keydown", handleEscapeKeyClick);
    }; // eslint-disable-next-line
  }, []);

  const handleEscapeKeyClick = (event) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(event.target) &&
      event.key === "Escape"
    ) {
      closeModal();
    }
  };

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  const navigate = useNavigate();

  const onClickLogout = () => {
    try {
      const devToken = JSON.parse(localStorage.getItem("devToken"));
      if (devToken) {
        const messaging = getMessaging();
        deleteToken(messaging)
          .then(() => {
          })
          .catch((err) => {
            console.log("Unable to delete token. ", err);
          });

        deleteDeviceToken(devToken);
      }
    } catch (err) {
      console.log(err);
    }

    navigate("/login");
    localStorage.removeItem("loginDetails");
    localStorage.removeItem("devToken");
    localStorage.removeItem("org");
  };

  const isModalOpen = useSelector((state) => state.mobile.isMobileMenu);

  const closeModal = () => {
    dispatch(closeMobileMenu());
  };

  const closeLogoutModal = () => {
    setLogoutClicked(false);
  };

  const navBarWidth = !menuClicked ? "expand-menu" : "collapse-menu";

  return (
    <div className="left-navigation-container">
      <nav
        style={{ backgroundColor: `${primaryColor}` }}
        className={`left-nav-bar large ${navBarWidth}`}
      >
        <ul className="nav-items-container">
          <li
            style={{ marginLeft: !menuClicked ? "200px" : "5px" }}
            className="hamburger-icon"
            onClick={onClickMenu}
          >
            {!menuClicked ? <AiOutlineDoubleLeft /> : <AiOutlineDoubleRight />}
          </li>
          {navItems.map((eachItem, index) => (
            <NavBarItems
              key={index}
              navitemDetails={eachItem}
              allEmployees={eachItem.navItemName === allEmployees}
              closeModal={closeModal}
            />
          ))}
        </ul>

        <hr className="" />
        <ul className="bottom-nav-container">
          {bottomNavItems.map((eachItem) => (
            <NavBarItems
              closeModal={closeModal}
              allEmployees={eachItem.navItemName === allEmployees}
              key={eachItem.id}
              navitemDetails={eachItem}
            />
          ))}
          <li
            // onClick={onClickLogout}
            onClick={() => setLogoutClicked(true)}
            className="d-flex align-items logout-list-item mt-2"
          >
            <div>
              <FiLogOut className="nav-icons " />
            </div>

            <p className="logout-btn-nav">Logout</p>
          </li>
        </ul>
      </nav>

      <PopupModal
        closeBtn={closeLogoutModal}
        modalName="Logout"
        showModal={logoutClicked}
      >
        <div className="logout-modal-container">
          <p style={{ fontSize: "14px" }}>Are you sure want to logout?</p>
          <div className="d-flex gap-2">
            <SecondaryButton onClick={() => setLogoutClicked(false)}>
              No
            </SecondaryButton>
            <PrimaryButton onClick={onClickLogout}>Yes</PrimaryButton>
          </div>
        </div>
      </PopupModal>

      {/* mobileNavbar */}
      <CSSTransition
        in={isModalOpen}
        timeout={300}
        classNames="slideMobileMenu"
        unmountOnExit
      >
        <LeftMobNavbar ref={modalRef} className="mobile-menu">
          <div className="close-menu-div">
            <button
              className="mobile-menu-close-icon"
              onClick={closeModal}
              type="button"
            >
              <BsX />
            </button>
          </div>
          <nav className="">
            <ul className="nav-items-container">
              {navItems.map((eachItem, index) => (
                <NavBarItems
                  key={index}
                  navitemDetails={eachItem}
                  allEmployees={eachItem.navItemName === allEmployees}
                />
              ))}
            </ul>
            <ul className="logout-container">
              <hr className="" />
              <li
                onClick={onClickLogout}
                className="d-flex align-items logout-list-item"
              >
                <div className="nav-item-icon">
                  <FiLogOut className="logout-icon" />
                </div>
                <button className="logout-btn-nav">Logout</button>
              </li>

              <Link style={{ textDecoration: "none" }} to="/settings">
                <li className="d-flex align-items logout-list-item">
                  <div className="nav-item-icon">
                    <FiSettings className="logout-icon" />
                  </div>
                  <button className="logout-btn-nav">Settings</button>
                </li>
              </Link>
            </ul>
          </nav>
        </LeftMobNavbar>
      </CSSTransition>
    </div>
  );
};

export default LeftNavBar;

import React, { useState } from "react";
import toast from "react-hot-toast";
import {
  StyledFileInput,
  StyledInput,
  StyledInputContainer,
  StyledInputGroup,
  StyledLabel,
  StyledUploadLabel,
} from "../../StyledCss/styledInputs";
import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";
import { exportLeaves, importLeaves } from "../../api/services/hroneApi";
import UploadingLoader from "../UploadingLoader";

const ApiExcelStatus = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const UploadLeaves = ({ setOpenPopupExcel }) => {
  const [uploadExcelError, setUploadExcelError] = useState("");
  const [apiExcelStatus, setApiExcelStatus] = useState(ApiExcelStatus.initial);
  const [selectedFile, setSelectedFile] = useState(null);

  const [fileUploadButtonDisabled, SetFileUploadButtonDisabled] =
    useState(false);

  const [fileSubmitButtonDisabled, SetFileSubmitButtonDisabled] =
    useState(true);

  const orgDetails = JSON.parse(localStorage.getItem("org"));
  const { primaryColor } = orgDetails;

  const fileUpload = () => toast.success("File uploaded successfully");
  const uploadExcel = async () => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    if (selectedFile !== null) {
      setApiExcelStatus(ApiExcelStatus.inProgress);
      try {
        const response = await importLeaves(formData);
        if (response.status === 200) {
          fileUpload();
          setApiExcelStatus(ApiExcelStatus.success);
          setOpenPopupExcel(false);
          setSelectedFile(null);
        }
      } catch (error) {
        const errorData = error.response.data.detail;
        setUploadExcelError(errorData);
        setApiExcelStatus(ApiExcelStatus.initial);
      }
    } else {
      setUploadExcelError("Please select a file to upload");
    }
  };
  const displayUploadingLoader = () => {
    switch (apiExcelStatus) {
      case ApiExcelStatus.inProgress:
        return <UploadingLoader />;
      case ApiExcelStatus.failure:
        return <p style={{ color: "red" }}>*Error uploading file</p>;
      default:
        return null;
    }
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (selectedFile === null || file !== undefined) {
      setSelectedFile(file);
      setUploadExcelError("");
      SetFileUploadButtonDisabled(true);
      SetFileSubmitButtonDisabled(false);
    } else {
      setSelectedFile(null);
    }
  };

  const handleExportLeavesSample = async () => {
    try {
      const response = await exportLeaves();
      if (response.status === 200) {
        const fileNameFromServer = response.headers["content-disposition"];
        const filename = fileNameFromServer
          ? fileNameFromServer.split("filename=")[1].replace(/"/g, "").trim()
          : "leaves_data.xlsx";
  
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
  
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = filename;
        downloadLink.click();
        URL.revokeObjectURL(downloadLink.href);
      }
    } catch (error) {
      console.error("Error while exporting data:", error);
    }
  };
  

  const hasValue = selectedFile !== null && selectedFile.name !== "";
  return (
    <div className="">
      <div>
        <div className="">
          <p
            style={{
              marginRight: "auto",
              cursor: "pointer",
              textDecoration: "underline",
              color: `${primaryColor}`,
              width: "fit-content",
            }}
            onClick={handleExportLeavesSample}
          >
            Download Sample File
          </p>
          <div className="d-flex align-items-center gap-3">
            {/* <div>
              <label
                className={
                  fileUploadButtonDisabled
                    ? "upload-excel-label-disabled"
                    : "upload-excel-label"
                }
                htmlFor="excel"
              >
                Upload Excel
              </label>
              <input
                disabled={fileUploadButtonDisabled}
                accept=".xlsx, .xls"
                onChange={handleFileSelect}
                id="excel"
                hidden
                type="file"
              />
            </div> */}

            {/* <StyledFileInput
              accept=".xlsx, .xls"
              onChange={handleFileSelect}
              id="fileInput"
              type="file"
              disabled={fileUploadButtonDisabled}
            /> */}

            {!selectedFile && (
              <StyledFileInput
                accept=".xlsx, .xls"
                onChange={handleFileSelect}
                id="fileInput"
                type="file"
                disabled={fileUploadButtonDisabled}
              />
            )}

            <StyledUploadLabel
              disabled={fileUploadButtonDisabled}
              htmlFor="fileInput"
              id="uploadBtn"
              style={
                fileUploadButtonDisabled
                  ? { color: "#999999" }
                  : { backgroundColor: `${primaryColor}`, color: "#ffffff" }
              }
            >
              Upload
            </StyledUploadLabel>

            <StyledInputGroup>
              <StyledInputContainer>
                <StyledInput
                  type="text"
                  name="text"
                  autoComplete="off"
                  readOnly
                  value={selectedFile !== null ? selectedFile.name : ""}
                />
                <StyledLabel className="user-label" hasValue={hasValue}>
                  File Name
                </StyledLabel>
              </StyledInputContainer>
            </StyledInputGroup>

            <SecondaryButton
              onClick={() => {
                setSelectedFile(null);
                SetFileUploadButtonDisabled(false);
                SetFileSubmitButtonDisabled(true);
                setUploadExcelError("");
              }}
            >
              Cancel
            </SecondaryButton>
          </div>

          <div className="d-flex justify-content-end gap-3 mt-3">
            <SecondaryButton onClick={() => setOpenPopupExcel()}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              disabled={fileSubmitButtonDisabled}
              onClick={() => uploadExcel()}
              className="uploadBtn-2"
            >
              Submit
            </PrimaryButton>
          </div>
        </div>
        {displayUploadingLoader()}
        {uploadExcelError.length > 0 && (
          <p style={{ color: "red" }}>*{uploadExcelError}</p>
        )}
      </div>
    </div>
  );
};

export default UploadLeaves;

/* eslint-disable react-hooks/exhaustive-deps */
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Toaster } from "react-hot-toast";
import { SecondaryButton } from "../../StyledCss/styledbtn";
import { getUserLeavesHistory } from "../../api/services/hroneApi";
import { convertKeysToCamelCase } from "../../util/convertCamelCase";
import formatDate from "../../util/formatdate";
import LeaveCancelForm from "../LeaveCancelForm/leaveCancelForm";
import PopupModal from "../PopupModal";
import "./index.css";

const LeaveHistoryTable = ({ startMonth, endMonth, refetchData }) => {
  const [rowData, setRowData] = useState([]);
  const [isLeaveCancelModalOpen, setIsLeaveCancelModalOpen] = useState(false);
  // const date = new Date();
  const formattedStartMonth = format(startMonth, "yyyy-MM");
  const formattedEndMonth = format(endMonth, "yyyy-MM");
  const [activeCancelId, setActiveCancelId] = useState("");
  const [requestType, setRequestType] = useState("");

  useEffect(() => {
    if (formattedStartMonth && formattedEndMonth) {
      getUserLeaves();
    }
  }, [formattedStartMonth, formattedEndMonth]);

  useEffect(() => {
    if (refetchData === true) {
      getUserLeaves();
    }
  }, [refetchData]);

  const getLeavesObj = {
    startMonth: formattedStartMonth,
    endMonth: formattedEndMonth,
  };

  const closeCancelLeaveModal = () => {
    setIsLeaveCancelModalOpen(false);
  };

  const handleButtonClick = async (params) => {
    setIsLeaveCancelModalOpen(true);
    setActiveCancelId(params.leaveId);
    setRequestType(params.requestType);
  };

  const getUserLeaves = async () => {
    try {
      const response = await getUserLeavesHistory(getLeavesObj);
      const data = await response.json();

      if (response.ok) {
        const camelCaseData = data.map((leave) =>
          convertKeysToCamelCase(leave)
        );
        const updatedData = camelCaseData.map((eachItem) => {
          const startDate = formatDate(eachItem.startDate);
          const endDate = formatDate(eachItem.endDate);
          return { ...eachItem, startDate, endDate };
        });
        setRowData(updatedData);
      }
    } catch (err) { }
  };
  const columnDefs = [
    {
      headerName: "START DATE",
      field: "startDate",
      floatingFilter: true,
      filter: true,
    },
    {
      headerName: "END DATE",
      field: "endDate",
      floatingFilter: true,
      filter: true,
    },
    {
      headerName: "REQUEST DAYS",
      field: "days",
      floatingFilter: true,
      filter: true,
    },
    {
      headerName: "REQUEST TYPE",
      field: "requestType",
      floatingFilter: true,
      filter: true,
    },
    {
      headerName: "STATUS",
      field: "status",
      floatingFilter: true,
      filter: true,
    },
    {
      headerName: "REJECTION / CANCEL REASON",
      field: "rejectedReason",
      width: 300,
      tooltipField: "rejectedReason",
      // floatingFilter: true,
      // filter: true,
    },
    {
      headerName: "CANCEL REQUEST",
      width: 200,
      cellRenderer: (params) => {
        const status = params.data.status;
        const startDate = params.data.startDate;
        const [day, month, year] = startDate.split('-');
        const appliedStartDate = new Date(year, month - 1, day);
        const currentDate = new Date();
        const currentDateOnly = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        const appliedStartDateOnly = new Date(appliedStartDate.getFullYear(), appliedStartDate.getMonth(), appliedStartDate.getDate());

        
        if (
          (status === "Awaiting") &&
          currentDateOnly !== appliedStartDateOnly
        ) {
          return (
            <SecondaryButton
              className="d-flex justify-content-center align-items-center"
              onClick={() => handleButtonClick(params.data)}
            >
              Cancel
            </SecondaryButton>
          );
        }

        return "N/A";
      },
    }

  ];
  const paginationSize = 12;

  return (
    <>
      <div
        className="ag-theme-alpine history-container"
        style={{
          height: "70vh",
          fontSize: "14px",
          fontFamily: "Arial, Helvetica, sans-serif",
        }}
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={true}
          paginationPageSize={paginationSize}
        ></AgGridReact>
        {/* <SlidingModal
          modalName="Leave Cancel"
          closeBtn={closeCancelLeaveModal}
          isModalOpen={isLeaveCancelModalOpen}
        >
          <LeaveCancelForm
            getUserLeaves={getUserLeaves}
            activeCancelId={activeCancelId}
            setIsLeaveCancelModalOpen={setIsLeaveCancelModalOpen}
          />
        </SlidingModal> */}

        <PopupModal
          closeBtn={closeCancelLeaveModal}
          modalName="Leave Cancel"
          showModal={isLeaveCancelModalOpen}
        >
          <LeaveCancelForm
            requestType={requestType}
            closeBtn={closeCancelLeaveModal}
            getUserLeaves={getUserLeaves}
            activeCancelId={activeCancelId}
            setIsLeaveCancelModalOpen={setIsLeaveCancelModalOpen}
          />
        </PopupModal>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default LeaveHistoryTable;
import React, { useState, useEffect, useCallback } from "react";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { convertKeysToCamelCase } from "../../util/convertCamelCase";
import { getLeavesSummary, exportLeaveSummary } from "../../api/services/hroneApi";
import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";
import LoadingView from "../LoadingView";
import PopupModal from "../PopupModal";

import "./index.css";

const LeavesSummary = () => {
  const date = new Date();
  const [startMonth, setStartMonth] = useState(date);
  const [endMonth, setEndMonth] = useState(date);
  const [leavesSummaryData, setLeavesSummaryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [LeaveSummaryPopUp, setLeaveSummaryPopUp] = useState(false); 


  const formattedStartMonth = format(startMonth, "yyyy-MM");
  const formattedEndMonth = format(endMonth, "yyyy-MM");

  const fetchLeavesSummaryData = useCallback(async () => {
    const defaultData = [
      {
        employeeId: "N/A",
        employeeName: "No Data Available",
        leavesCount: "0",
      },
    ];
    setIsLoading(true);
    try {
      const response = await getLeavesSummary(formattedStartMonth, formattedEndMonth);
      if (response.status === 200) {
        const data = await response.json();
        const updatedSummaryData = data.map((eachItem) =>
          convertKeysToCamelCase(eachItem)
        );
        setLeavesSummaryData(updatedSummaryData.length > 0 ? updatedSummaryData : defaultData);
      } else {
        setLeavesSummaryData(defaultData);
      }
    } catch (error) {
      console.error("Error fetching data:", error)
      setLeavesSummaryData(defaultData);
    } finally {
      setIsLoading(false);
    }
  }, [formattedStartMonth, formattedEndMonth]); // Removed `defaultData` from the dependencies



  useEffect(() => {
    fetchLeavesSummaryData();
  }, [fetchLeavesSummaryData]);


  const handleExportData = async () => {
  
    try {
      const queryArg = {
        from_year_month: formattedStartMonth,
        to_year_month: formattedEndMonth,
      };
    const response = await exportLeaveSummary(queryArg);

      if (response.status === 200) {

        const contentDisposition = response.headers["content-disposition"];
        const filename = contentDisposition
          ?.split("filename=")[1]
          ?.replace(/"/g, "")
          .trim();
        const blob = response.data;
        const downloadLink = document.createElement("a");
        const blobUrl = URL.createObjectURL(blob);
        downloadLink.href = blobUrl;
        downloadLink.download = filename || "download.xlsx";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(blobUrl);
      } else {
        console.error("Error exporting data: ", response.statusText);
      }
    } catch (error) {
      console.error("Error exporting data:", error);
    } 
  };

  const PopupModalContent = ({ closeModal, onSubmit }) => (
    <div className="d-flex align-items-center justify-content-end">
    <div className="d-flex flex-column flex-wrap align-items-center justify-content-center gap-3">
      <div className="month-picker d-flex flex-column">
        <label className="mb-1">Start Month</label>
        <DatePicker
          selected={startMonth}
          onChange={(date) => setStartMonth(date)}
          showMonthYearPicker
          dateFormat="MMMM yyyy"
          className="date-input-label"
        />
      </div>
      <div className="month-picker d-flex flex-column">
        <label className="mb-1">End Month</label>
        <DatePicker
          selected={endMonth}
          onChange={(date) => setEndMonth(date)}
          showMonthYearPicker
          dateFormat="MMMM yyyy"
          className="date-input-label"
        />
      </div>
      <div className="d-flex justify-content-end gap-3 mt-3">
        <SecondaryButton style={{ marginRight: "10px" }} onClick={closeModal}>
          Cancel
        </SecondaryButton>
        <PrimaryButton onClick={onSubmit} type="button">
          Submit
        </PrimaryButton>
      </div>
    </div>
    </div>
  );

  
  const handleOpenPopup = () => {
    setLeaveSummaryPopUp(true);
  };
  
  const closeLeaveSummaryPopUp = () => {
    setLeaveSummaryPopUp(false);
  };
  
  const handlePopupSubmit = () => {
    handleExportData();
    closeLeaveSummaryPopUp();
  };
  



  const columnsLeaveSummary = [
    {
      headerName: "EMP ID",
      field: "employeeId",
      floatingFilter: true,
      filter: true,
      width: 100,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "NAME",
      field: "employeeName",
      filter: true,
      sortable: true,
      floatingFilter: true,
      tooltipField: "employeeName",
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "LEAVES COUNT",
      field: "leavesCount",
      floatingFilter: true,
      filter: true,
      width: 120,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
  ];

  const paginationPageSize = 20;

  const selectDates = () => (
    <div className="d-flex align-items-center justify-content-end">
      <div className="d-flex flex-row flex-wrap align-items-center justify-content-end gap-3">
        <div className="month-picker d-flex flex-column">
          <label className="mb-1">Select Month</label>
          <DatePicker
            selected={startMonth}
            onChange={(date) => setStartMonth(date)}
            showMonthYearPicker
            dateFormat="MMMM yyyy"
            className="date-input-label"
          />
        </div>
        <div className="d-flex flex-column align-self-end me-3">
          <PrimaryButton
            style={{
              textAlign: "center",
              width: "auto",
            }}
            type="button"
            onClick={handleOpenPopup}
          >
            Export Leave Summary
          </PrimaryButton>
        </div>
      </div>
    </div>
  );

  const renderLeavesSummary = () => (
    <div
      className="ag-theme-alpine"
      style={{
        fontSize: "14px",
        margin: "10px 15px",
        fontFamily: "Arial, Helvetica, sans-serif",
        textAlign: "center",
      }}
    >
      <AgGridReact
        rowData={leavesSummaryData}
        columnDefs={columnsLeaveSummary}
        pagination={true}
        paginationPageSize={paginationPageSize}
        domLayout="autoHeight"
      ></AgGridReact>
    </div>
  );

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <LoadingView />
        </div>
      )}
   <PopupModal
        modalName="Export Leave Summary"
        closeBtn={closeLeaveSummaryPopUp}
        showModal={LeaveSummaryPopUp}
      >
        <PopupModalContent
          closeModal={closeLeaveSummaryPopUp}
          onSubmit={handlePopupSubmit}
        />
      </PopupModal>
      {selectDates()}
  
      {renderLeavesSummary()}
      {/* <Toaster position="top-center" reverseOrder={false} /> */}
    </>
  );
  
};

export default LeavesSummary;

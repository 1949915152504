/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";
import {
  getEmployeeLead,
  getHolidaysByYear,
  getLeavesCount,
  getUserData,
} from "../../api/services/hroneApi";
import Header from "../Header";
import LeaveForm from "../LeaveForm";
import LeaveManagement from "../LeaveManagment";
import LeaveRequestsRoute from "../LeaveRequestsRoute";
// import LeavesSummary from "../LeavesSummary";
import LeftNavBar from "../LeftNavBar";
import PageHeader from "../PageHeader";
import SlidingModal from "../SlidingModal/slidingModal";
// import TotalLeavesHistory from "../TotalLeavesHistory";
import "./index.css";

import PopupModal from "../PopupModal";
import { PageHeaderName } from "../../StyledCss/styledComponents";
// const apiStatusConstants = {
//   initial: "INITIAL",
//   success: "SUCCESS",
//   failure: "FAILURE",
//   inProgress: "IN_PROGRESS",
// };
const LeaveRequestsManagement = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("Your History");
  // const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);
  const [leavesData, setLeavesData] = useState([]);
  // const [isOpacity, setIsOpacity] = useState(false);
  const [hrList, setHrList] = useState([]);
  const [holidaysList, setHolidaysList] = useState([]);
  const [applyLeave, setApplyLeave] = useState(false);
  const [refetchData, setRefetchData] = useState(false);
  const [isWarningModal, setIsWarningModal] = useState(false);

  const leaveApplied = () => {
    setIsModalOpen(false);
    setApplyLeave(!applyLeave);
    setRefetchData(true);
  };

  const closeBtn = () => {
    setIsModalOpen(false);
    // setIsOpacity(false);
  };
  const pageTabsList = [
    {
      id: 1,
      name: "Your History",
      roles: ["Super Admin", "Admin", "Employee", "HR", "Lead"],
    },
    {
      id: 2,
      name: "Requests",
      roles: ["Super Admin", "Admin", "Lead"],
    },
    
  ];

  useEffect(() => {
    fetchLeavesCount();
  }, []);

  const { role } = getUserData();
  const orgDetails = JSON.parse(localStorage.getItem("org"));
  const { primaryColor } = orgDetails;

  const tabs = () => (
    <div className="d-flex align-items-center leaves-tab-container">
      {pageTabsList.map(
        (eachTab) =>
          eachTab.roles.includes(role) && (
            <button
              style={
                activeTab === eachTab.name
                  ? {
                      color: primaryColor,
                      borderBottom: `3px solid ${primaryColor}`,
                    }
                  : {}
              }
              className={
                activeTab === eachTab.name ? "leave-active-tab" : "leave-tab"
              }
              onClick={() => setActiveTab(eachTab.name)}
              key={eachTab.id}
            >
              {eachTab.name}
            </button>
          )
      )}
    </div>
  );

  const fetchLeavesCount = async () => {
    // setApiStatus(apiStatusConstants.inProgress);
    try {
      const holidaysYear = date.getFullYear();
      const response = await getLeavesCount();
      const responsehrList = await getEmployeeLead();
      const responseHolidaysList = await getHolidaysByYear(holidaysYear);

      if (response.status === 200) {
        // setApiStatus(apiStatusConstants.success);
        const data = await response.json();
        const camelCaseData = {
          yearlyBalanceLeaves: data.balance_leaves,
          totalLeaves: data.total_leaves,
          usedLeaves: data.used_leaves,
          currentMonthBalanceLeaves: data.current_month_leaves,
          extraWorkingDays: data.extra_working_days,
        };
        setLeavesData(camelCaseData);
      } else {
        // setApiStatus(apiStatusConstants.failure);
      }

      if (responseHolidaysList.status === 200) {
        const holidaysData = await responseHolidaysList.json();
        setHolidaysList(holidaysData);
      }

      if (responsehrList.status === 200) {
        const hrData = await responsehrList.json();
        setHrList(hrData);
      }
    } catch (err) {
      // setApiStatus(apiStatusConstants.failure);
    }
  };

  const renderActiveTabPage = () => {
    switch (activeTab) {
      case "Your History":
        return <LeaveManagement refetchData={refetchData} />;
      case "Requests":
        return <LeaveRequestsRoute />;
      // // case "All History":
      // return null;
      // // return <TotalLeavesHistory />;
      // case "Summary":
      // return null;
      // // return <LeavesSummary />;
      default:
        return null;
    }
  };
  const date = new Date();
  const currentMonth = date.toLocaleString("default", { month: "short" });
  const notifyLeaveApplied = (data) => toast.success(data);
  const leavesCountSection = () => {
    const {
      usedLeaves,
      yearlyBalanceLeaves,
      totalLeaves,
      currentMonthBalanceLeaves,
      extraWorkingDays,
    } = leavesData;

    return (
      <div className="widget-card-container">
        <div className="widget-card">
          <p className="leave-status">{totalLeaves}</p>
          <p>Yearly Total</p>
        </div>

        <div className="widget-card">
          <p className="leave-status">{yearlyBalanceLeaves}</p>
          <p>Yearly Balance</p>
        </div>
        <div className="widget-card">
          <p className="leave-status">{usedLeaves}</p>
          <p>Yearly Utilized </p>
        </div>

        <div className="widget-card">
          <p className="leave-status">{currentMonthBalanceLeaves}</p>
          <p>Leaves Left Upto {currentMonth.toLocaleUpperCase()} </p>
        </div>
        <div className="widget-card">
          <p className="leave-status">{extraWorkingDays}</p>
          <p>
            Extra {extraWorkingDays > 1 ? "Works" : "Work"} In{" "}
            {currentMonth.toLocaleUpperCase()}{" "}
          </p>
        </div>
      </div>
    );
  };

  const handleApplyBtn = () => {
    setRefetchData(false);
    const { currentMonthBalanceLeaves } = leavesData;

    if (currentMonthBalanceLeaves > 0) {
      setIsModalOpen(true);
    } else {
      setIsWarningModal(true);
      // setIsOpacity(true);
    }
  };

  const closeWarningModal = () => {
    setIsModalOpen(false);
    setIsWarningModal(false);
    // setIsOpacity(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
    setIsWarningModal(false);
    // setIsOpacity(true);
  };

  const renderWarningModal = () => {
    return (
      // <div className="warning-modal-container">
      <div className="d-flex flex-column">
        <p>
          These leaves are considered under Loss of Pay. Would you like to
          continue?
        </p>
        <div className="d-flex flex-row justify-content-center gap-5">
          <SecondaryButton onClick={closeWarningModal}>Cancel</SecondaryButton>

          <PrimaryButton onClick={openModal}>Ok</PrimaryButton>
        </div>
      </div>
      // </div>
    );
  };

  return (
    <>
      <Header />
      <div className="d-flex">
        <LeftNavBar />
        <div className="leaves-req-hist-container">
          <PageHeader>
            <PageHeaderName>Leave Management</PageHeaderName>
            <PrimaryButton onClick={handleApplyBtn}>Apply Leave</PrimaryButton>
          </PageHeader>
          <div className="leaves-count-section">{leavesCountSection()}</div>

          <div className="page-content-container">
            {tabs()}
            {renderActiveTabPage()}
            {isWarningModal && renderWarningModal()}

            <PopupModal
              modalName="LOP Warning"
              closeBtn={closeWarningModal}
              showModal={isWarningModal}
            >
              {renderWarningModal()}
            </PopupModal>

            <SlidingModal
              modalName="Apply Leave"
              closeBtn={closeBtn}
              isModalOpen={isModalOpen}
            >
              <LeaveForm
                hrList={hrList}
                holidaysList={holidaysList}
                leaveApplied={leaveApplied}
                notifyLeaveApplied={notifyLeaveApplied}
                closeBtn={closeBtn}
              />
            </SlidingModal>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveRequestsManagement;

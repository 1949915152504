import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";
// import { AiFillDelete } from "react-icons/ai";
import "./index.css";
// import UploadingLoader from "../UploadingLoader";
import { HiOutlinePencil } from "react-icons/hi2";
import { DetailsText, DetailsTextLabel } from "../../StyledCss/styledCss";
import {
  // deleteProfilePicture,
  getProfile,
  // getUserData,
  updateEmployee,
} from "../../api/services/hroneApi";
import formatDate from "../../util/formatdate";

import { TabHeading } from "../../StyledCss/styledCss";
import {
  StyledInput,
  StyledInputContainer,
  StyledInputGroup,
  StyledLabel,
  StyledSelect,
  StyledSelectContainer,
  StyledSelectGroup,
} from "../../StyledCss/styledInputs";
const apiStatusConstants = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const Pinfo = ({ refreshPage }) => {
  const [employeeData, setEmployeeData] = useState({});
  const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);
  const [editProfile, setEditProfile] = useState(false);

  const notifySaved = () => toast.success("Details Updated Successfully");
  const notifyError = () => toast.error("Something Went Wrong");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    getUserDetails(); // eslint-disable-next-line
  }, []);

  const getUserDetails = async () => {
    setApiStatus(apiStatusConstants.inProgress);
    try {
      const response = await getProfile();
      if (response.status === 200) {
        const data = await response.json();
        const updatedData = {
          id: data.id,
          firstName: data.first_name,
          lastName: data.last_name,
          dob: data.date_of_birth,
          qualifications: data.qualifications,
          department: data.department,
          bloodGroup: data.blood_group,
          gender: data.gender,
          mobileNo: data.mobile_number,
          email: data.email,
          location: data.address,
          designation: data.designation,
          joiningDate: data.joining_date,
          role: data.role,
          address: data.address,
          emergencyContactName: data.emergency_contact_name,
          emergencyContactNumber: data.emergency_contact_number,
          profilePicUrl: data.profile_picture_url,
        };

        // setProfilePicUrl(data.profile_picture_url);

        setEmployeeData(updatedData);
        setApiStatus(apiStatusConstants.success);
      } else {
        setApiStatus(apiStatusConstants.failure);
      }
    } catch (e) {
      setApiStatus(apiStatusConstants.failure);
    }
  };

  const ProfileInfoSection = () => (
    <div className="profile-info-container">
      <div>
        <DetailsTextLabel>Full Name</DetailsTextLabel>
        <DetailsText>
          {employeeData.firstName} {employeeData.lastName}
        </DetailsText>
      </div>
      <div>
        <DetailsTextLabel>Gender</DetailsTextLabel>
        <DetailsText>{employeeData.gender}</DetailsText>
      </div>
      <div>
        <DetailsTextLabel>Date Of Birth</DetailsTextLabel>
        <DetailsText>{formatDate(employeeData.dob)}</DetailsText>
      </div>
      <div>
        <DetailsTextLabel>Phone Number</DetailsTextLabel>
        <DetailsText>{employeeData.mobileNo}</DetailsText>
      </div>

      <div>
        <DetailsTextLabel>Blood Group</DetailsTextLabel>
        <DetailsText>{employeeData.bloodGroup}</DetailsText>
      </div>

      <div>
        <DetailsTextLabel>Address</DetailsTextLabel>
        <DetailsText>{employeeData.address}</DetailsText>
      </div>

      <TabHeading style={{ fontSize: "14px" }}>Emergency Details</TabHeading>
      <div>
        <DetailsTextLabel>Contact Name</DetailsTextLabel>
        <DetailsText>{employeeData.emergencyContactName}</DetailsText>
      </div>

      <div>
        <DetailsTextLabel>Contact Number</DetailsTextLabel>
        <DetailsText>{employeeData.emergencyContactNumber}</DetailsText>
      </div>
    </div>
  );

  const onSubmit = (data) => {
    onClickSubmitChanges(data);
  };

  const onClickSubmitChanges = async (data) => {
    try {
      const response = await updateEmployee(data);
      if (response.ok) {
        setEditProfile(!editProfile);
        notifySaved();
        setTimeout(() => {
          getUserDetails();
        }, 1000);
      }
    } catch (error) {
      notifyError();
    }
  };


  const editProfileSection = () => (
    <div className="edit-profile-info-container d-flex ">
      <div className="edit-account-details">{EditProfileForm()}</div>
    </div>
  );

  const EditProfileForm = () => (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <div className="edit-profile-container">


        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              style={errors.dob && { borderLeft: "10px solid red" }}
              inputError={errors.dob}
              defaultValue={employeeData.dob}
              {...register("dob", { required: true })}
              type="date"
            />
            <StyledLabel className="user-label">DOB*</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>



        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              style={errors.mobileNumber && { borderLeft: "10px solid red" }}
              inputError={errors.mobileNumber}
              defaultValue={employeeData.mobileNo}
              {...register("mobileNumber", {
                required: true,
                maxLength: 10,
                pattern: /[0-9]{10}/,
              })}
              type="tel"
            />
            <StyledLabel className="user-label">Mobile No*</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>


        <StyledSelectGroup>
          <StyledSelectContainer>
            <StyledSelect
              style={errors.bloodGroup && { borderLeft: "10px solid red" }}
              defaultValue={employeeData.bloodGroup}
              required
              name="select"
              {...register("bloodGroup", { required: true })}
            >
              <option className="option-bg" value="A+">
                A+
              </option>
              <option value="B+">B+</option>
              <option value="AB+">AB+</option>
              <option value="O+">O+</option>
              <option value="A-">A-</option>
              <option value="B-">B-</option>
              <option value="AB-">AB-</option>
              <option value="O-">O-</option>
            </StyledSelect>
            <StyledLabel className="user-label">Blood Group</StyledLabel>
          </StyledSelectContainer>
        </StyledSelectGroup>



        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              style={errors.location && { borderLeft: "10px solid red" }}
              defaultValue={employeeData.location}
              {...register("location", {
                required: true,
                pattern: /^[A-Za-z]{3,20}/i,
              })}
              type="text"
            />
            <StyledLabel className="user-label">Location*</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>



        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              style={
                errors.emergencycontactName && { borderLeft: "10px solid red" }
              }
              defaultValue={employeeData.emergencyContactName}
              {...register("emergencycontactName", {
                required: true,
                pattern: /^[A-Za-z]{3,10}/,
              })}
              type="text"
            />
            <StyledLabel className="user-label">
              Emergency Contact Name*
            </StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>



        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              style={
                errors.emergencyContactNumber && {
                  borderLeft: "10px solid red",
                }
              }
              defaultValue={employeeData.emergencyContactNumber}
              {...register("emergencyContactNumber", {
                required: true,
                maxLength: 10,
                pattern: /[0-9]{10}/,
              })}
              type="tel"
            />
            <StyledLabel className="user-label">
              Emergency Contact No*
            </StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>
      </div>

      <div className="d-flex justify-content-end gap-3">
        <SecondaryButton
          type="button"
          onClick={() => {
            setEditProfile(false);
            reset();
          }}
        >
          Cancel
        </SecondaryButton>

        <PrimaryButton type="submit">Submit</PrimaryButton>
      </div>
    </form>
  );

  const renderProfile = () => {
    switch (apiStatus) {
      case apiStatusConstants.inProgress:
        return (
          <div className="d-flex justify-content-center w-100">
            <div className="spinner-border text-primary " role="status"></div>
          </div>
        );
      case apiStatusConstants.success:
        return ProfileInfoSection();
      case apiStatusConstants.failure:
        return null;
      default:
        return null;
    }
  };

  const onClickEditProfile = () => {
    setEditProfile(!editProfile);
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <TabHeading>Personal Info</TabHeading>
        <button onClick={onClickEditProfile} className="editIcon" type="button">
          <HiOutlinePencil size={20} />
        </button>
      </div>
      {editProfile ? editProfileSection() : renderProfile()}

      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default Pinfo;
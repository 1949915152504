import styled from "styled-components";

const orgDetails = JSON.parse(localStorage.getItem("org"));
let mainColor = "#e86020";
if (orgDetails && orgDetails.primaryColor) {
  mainColor = orgDetails.primaryColor;
}

export const Button = styled.button`
  background-color: #e86020;
  color: #ffffff;
  height: 36px;
  width: fit-content;
  border: 0px none;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: 500;
  margin-top: 10px;
  min-width: 100px;
  font-size: 14px;
  padding: 6px 12px;
`;

export const AcceptButton = styled.button`
  background-color: #e86020;
  color: white;
  height: 28px;
  width: fit-content;
  border: 0px none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  margin-top: 6px;
  min-width: 100px;
  font-size: 14px;
`;

export const RejectButton = styled.button`
  background-color: transparent;
  color: #e86020;
  height: 28px;
  width: fit-content;
  border: 1px solid #e86020;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  margin-top: 6px;
  min-width: 100px;
  font-size: 14px;
`;

export const UploadButton = styled.label`
  background-color: #00adee;
  color: white;
  height: 35px;
  width: fit-content;
  border: 0px none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  margin-top: 10px;
  min-width: 100px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  padding: 5px 10px 15px 10px;
`;

// ${(props) => props.bgColor

export const LoginPageButton = styled.button`
  background-color: #e86020;
  height: 40px;
  width: 320px;
  border-radius: 4px;
  ${"" /* padding: 8px, 0px, 8px, 22px; */}
  border: 0px none;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PrimaryButton = styled.button`
  ${"" /* background-color: #e86020; */}
  background-color: ${(props) => (props.disabled ? "#ccc" : `${mainColor}`)};

  height: 36px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  width: ${(props) => props.width || "126px"};
  border: 0px none;
  color: #ffffff;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  outline: none;
`;

export const SecondaryButton = styled.button`
  background-color: transparent;
  border: 1px solid ${mainColor};
  height: 36px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  width: ${(props) => props.width || "126px"};
  color: ${mainColor};
  outline: none;
`;



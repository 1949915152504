import React from "react";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { convertKeysToCamelCase } from "../../util/convertCamelCase";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import formatDate from "../../util/formatdate";
import "./index.css";
import { getEmployeesLeavesHistory } from "../../api/services/hroneApi";
import LoadingView from "../LoadingView";
const TotalLeavesHistory = () => {
  const date = new Date();
  const [startMonth, setStartMonth] = useState(date);
  const [endMonth, setEndMonth] = useState(date);
  const [leavesHistoryData, setLeavesHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false); 
  //   const id = loginDetails.details.response.id;
  const formattedStartMonth = format(startMonth, "yyyy-MM");
  const formattedEndMonth = format(endMonth, "yyyy-MM");

  useEffect(() => {
    getLeavesHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startMonth, endMonth]);

  const getLeavesHistory = async () => {
    setIsLoading(true);
    try {
      const response = await getEmployeesLeavesHistory(
        formattedStartMonth,
        formattedEndMonth
      );
      if (response.ok) {
        const data = await response.json();
        const updatedHistoryData = data.map((eachItem) =>
          convertKeysToCamelCase(eachItem)
        );

        const updatedData = updatedHistoryData.map((eachItem) => {
          const startDate = formatDate(eachItem.startDate);
          const endDate = formatDate(eachItem.endDate);
          return { ...eachItem, startDate, endDate };
        });

        setLeavesHistoryData(updatedData);
      }
    } catch (error) {}
  
    finally{
      setIsLoading(false);
    }
  };

  const columnsLeaveHistory = [
    {
      headerName: "EMP ID",
      field: "employeeId",
      width: 120,
      floatingFilter: true,
      filter: true,
    },

    {
      headerName: "NAME",
      field: "fullName",
      filter: true,
      sortable: true,
      floatingFilter: true,
      editable: true,
      tooltipField: "fullName",
    },
    {
      headerName: "START DATE",
      field: "startDate",
      floatingFilter: true,
      filter: true,
    },
    {
      headerName: "END DATE",
      field: "endDate",
      floatingFilter: true,
      filter: true,
    },
    {
      headerName: "REQUEST TYPE",
      field: "requestType",
      floatingFilter: true,
      filter: true,
    },
    {
      headerName: "DAYS",
      field: "days",
      width: 100,
      floatingFilter: true,
      filter: true,
    },
    {
      headerName: "STATUS",
      field: "status",
      width: 150,
      floatingFilter: true,
      filter: true,
    },
    {
      headerName: "APPROVED BY",
      field: "approvedBy",
      width: 200,
      floatingFilter: true,
      filter: true,
    },
    {
      headerName: "REASON",
      field: "leaveReason",
      tooltipField: "leaveReason",
      width: 300,
      floatingFilter: true,
      filter: true,
    },
    {
      headerName: "REJECT REASON",
      field: "rejectedReason",
      tooltipField: "rejectedReason",
      width: 300,
      floatingFilter: true,
      filter: true,
    },
  ];

  const paginationPageSize = 20;

  const selectDates = () => (
    <div className="d-flex align-items-center justify-content-end me-3">
      <div className="d-flex flex-row flex-wrap justify-content-end">
        <div className="month-picker d-flex flex-column me-1">
          <label className="mb-1">Start Month</label>
          <DatePicker
            selected={startMonth}
            onChange={(date) => {
              if (date) {
                setStartMonth(date);
              } else {
                setStartMonth(new Date());
              }
            }}
            showMonthYearPicker
            dateFormat="MMMM yyyy"
            className="date-input-label"
          />
        </div>
        <div className="month-picker d-flex flex-column">
          <label className="mb-1">End Month</label>
          <DatePicker
            selected={endMonth}
            onChange={(date) => {
              if (date) {
                setEndMonth(date);
              } else {
                setEndMonth(new Date());
              }
            }}
            showMonthYearPicker
            dateFormat="MMMM yyyy"
            className="date-input-label"
          />
        </div>
      </div>
    </div>
  );

  const renderLeavesHistory = () => (
    <div
      className="ag-theme-alpine "
      style={{
        height: "70vh",
        // width: "85vw",
        fontSize: "14px",
        marginLeft: "15px",
        marginRight: "15px",
        marginTop: "10px",
        fontFamily: "Arial, Helvetica, sans-serif",
      }}
    >
      {/* {selectDates()} */}
      <AgGridReact
        // domLayout="autoHeight"
        rowData={leavesHistoryData}
        columnDefs={columnsLeaveHistory}
        pagination={true}
        paginationPageSize={paginationPageSize}
      ></AgGridReact>
    </div>
  );

  return (
    <>
     {isLoading && <LoadingView />}
      {selectDates()}
      {renderLeavesHistory()}
    </>
  );
};

export default TotalLeavesHistory;
